import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import '../styling/LoginPage.css';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    const handleNextStep = (e) => {
        e.preventDefault();
        if (email) {
            setStep(2);
        } else {
            setMessage(translate('Please enter your email.'));
        }
    };

    const handleBackToEmail = (e) => {
        e.preventDefault();
        setStep(1);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/login', { email, password });
            if (response.data.accesstoken && response.data.refreshtoken) {
                localStorage.setItem('accessToken', response.data.accesstoken);
                localStorage.setItem('refreshToken', response.data.refreshtoken);
                setMessage(translate('Login successful!'));
                navigate('/tasks');  // Redirect to dashboard
            }
            if (response.status === 400) {
                setMessage(translate('Login failed. Please try again.'));
                console.error('Error:', response);
            }
        } catch (error) {
            setMessage(translate('Login failed. Please try again.'));
            console.error('Error:', error);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-header">
                    <span className="login-logo">Month 1</span>
                    <h1>{step === 1 ? translate('Enter your email') : translate('Enter your password')}</h1>
                </div>
                <form onSubmit={step === 1 ? handleNextStep : handleLogin} className="login-form">
                    {step === 1 ? (
                        <>
                            <div className="input-group">
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={translate('Your email address')}
                                    required
                                />
                            </div>
                            <button type="submit" className="next-button">{translate('Next')}</button>
                        </>
                    ) : (
                        <>
                            <div className="input-group">
                                <input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={translate('Password')}
                                    required
                                />
                            </div>
                                <button type="submit" className="login-button">{translate('Log in')}</button>
                            <a href="#" onClick={handleBackToEmail} className="back-link">
                                    {translate('Not you? Use a different account')}
                            </a>
                        </>
                    )}
                </form>
                <div className="login-footer">
                    {step === 2 && <a href="/forgot-password">{translate('Forgot password?')}</a>}
                </div>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default LoginPage;