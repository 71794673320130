import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NewButton, SearchComponent, ExportComponent } from '../../includes';
import { FaRegEdit, FaRegTrashAlt, FaHome } from "react-icons/fa";
import ConfirmationModal from '../../includes/ConfirmationModal';
import { MdBusinessCenter } from "react-icons/md";
import { useTranslation } from 'react-i18next';


const Locations = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);

    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        navigate('/organisation/location', { state: { locationId: row.id } });
    }, [navigate]);


    const columns = [
        {
            name: translate('Name'),
            selector: row => <a href="#" onClick={(e) => handleEdit(e, row)}>{row.name}</a>,
            sortable: true,
            reorder: true,
        },
        {
            name: translate('Country'),
            selector: row => row.country,
            sortable: true,
            reorder: true,
        },
    ];

    useEffect(() => {
        setActivePage(translate('Locations'));

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/locations');
                setData(response.data);
                setFilteredItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <div className="table-header">
                <NewButton to="/organisation/location" title={translate('+ New Location')} />
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by name')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Locations;