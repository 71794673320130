import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiPlus, FiInfo } from 'react-icons/fi';
import Modal from '../../includes/PopupModal';
import BoardingflowPopup from './Boardingflowpopup';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { FaFlagCheckered } from "react-icons/fa"; 
import { useTranslation } from 'react-i18next';
import { IoCheckmarkOutline } from "react-icons/io5";

const Preboardingflow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const flowid = location.state?.flowid ?? 0;
    const type = 1;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();  
    const [loading, setLoading] = useState(true); 
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible] = useState(false); 
    const [selectedAnchor, setSelectedAnchor] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const containerRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [items, setItems] = useState([]);
    const [draggingItem, setDraggingItem] = useState(null);
    const [dragStartX, setDragStartX] = useState(0);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [lastMovedItemId, setLastMovedItemId] = useState(null);
    const [editingItem, setEditingItem] = useState(null);
    const [getIndex, setIndex] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [inputValues, setInputValues] = useState({
        currentstep: 3,
        id: 0,
        name: ''
    });



    const AnchorNode = ({ color, isTop, onMouseDown }) => (
        <div onMouseDown={onMouseDown}>
            {isTop ? (
                <svg height="240" width="134" xmlns="http://www.w3.org/2000/svg">
                    <circle r="45" cx="67" cy="50" fill="transparent" stroke="#afafaf" strokeWidth="3" />
                    <circle r="26" cx="67" cy="50" fill="transparent" stroke={color} strokeWidth="3" />
                    <circle r="11" cx="67" cy="50" fill={color} stroke={color} strokeWidth="3" />
                    <path stroke={color} d="M67 224 67 60" strokeWidth="3" />
                    <circle r="5" cx="67" cy="228" fill={color} stroke={color} strokeWidth="3" />
                </svg>
            ) : (
                <svg height="240" width="134" xmlns="http://www.w3.org/2000/svg">
                    <circle r="45" cx="67" cy="190" fill="transparent" stroke="#afafaf" strokeWidth="3" />
                    <circle r="26" cx="67" cy="190" fill="transparent" stroke={color} strokeWidth="3" />
                    <circle r="11" cx="67" cy="190" fill={color} stroke={color} strokeWidth="3" />
                    <path stroke={color} d="M67 12 67 180" strokeWidth="3" />
                    <circle r="5" cx="67" cy="12" fill={color} stroke={color} strokeWidth="3" />
                </svg>
            )}
        </div>
    );

    useEffect(() => {

        const fetchItem = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/flow/getboardingflow/' + flowid);

                setInputValues(response.data);
                setItems(response.data.actions.map((item, index) => {
                    setIndex(index + 100);
                    return ({ ...item, id: index + 100, dbid: item.id, isHidden: false })
                }));
                setActivePage(translate('Preboarding workflow - ' + response.data.name));
            } catch (err) {
                console.error('An error occurred while retrieving the data :', err);
            } finally {
                setLoading(false);
            }
        };
         
        if (flowid !== 0) {
            fetchItem();
        } else {
            navigate('/boarding/preboarding');
            setLoading(false);
        }

        setActivePage(translate('New preboarding'));

        //laden
        setLoading(false);
    }, [setActivePage, translate, flowid]);


    const sortItems = (itemsToSort) => {
        return [...itemsToSort].sort((a, b) => b.days - a.days);
    };

    const { sortedItems, maxDays } = useMemo(() => {
        const sorted = sortItems(items);
        const max = sorted.length > 0 ? Math.max(...sorted.map(item => item.days)) : 0;
        return { sortedItems: sorted, maxDays: max };
    }, [items]);


    const moveItem = (itemId, direction) => {
        setItems(prevItems =>
            prevItems.map(item => {
                if (item.id === itemId) {
                    const newDaysBefore = Math.max(0, item.days + direction);
                    return { ...item, days: newDaysBefore };
                }
                return item;
            })
        );
        setLastMovedItemId(itemId);

        saveItemChange(itemId, direction);
    };

    const editItem = (itemId) => {
        const item = items.find(item => item.id === itemId);
        setEditingItem(item);
        setIsModalOpen(true);
    };

    const deleteItem = (itemId) => {
        setSelectedAnchor(itemId);
        setIsDeleteModalOpen(true);
    };

    const saveItemChange = async (itemId, direction) => {
        try {
            const item = items.find(item => item.id === itemId);
            if (item) {
                const newDays = Math.max(0, item.days + direction);

                await axiosInstance.post('/flow/boardingflow/anchordays', {
                    flowid: flowid,
                    id: item.dbid,
                    days: newDays
                });
                console.log("Item successfully saved");
            }
        } catch (error) {
            console.error("Error saving item:", error);
        }
    };

    const handleMouseDown = (e, item) => {
        setDraggingItem(item);
        setDragStartX(e.clientX);
        setLastMovedItemId(item.id);
    };


    const handleDeleteAnchor = useCallback(async () => {
        if (selectedAnchor !== null) {
            try {
                const item = items.find(item => item.id === selectedAnchor);
                if (item) { 
                    await axiosInstance.post('/flow/boardingflow/deleteanchor', { flowid: flowid, anchorid: item.dbid });
                    setItems(prevItems => prevItems.map(i =>
                        i.id === selectedAnchor ? { ...i, isHidden: true } : i
                    ));
                    setSuccessMessage(translate('Anchor successfully removed'));
                    setTimeout(() => setSuccessMessage(''), 2500);
                }
            } catch (error) {
                console.error('Error deleting anchor:', error);
            } finally {
                setSelectedAnchor(null);
                setIsDeleteModalOpen(false);
            }
        }
    }, [selectedAnchor, items, flowid, translate]);

    const openModal = () => {
        setEditingItem(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (updatedItemData) => {
        setLoading(true);
        try {
            let response;
            updatedItemData.flowid = flowid;

            if (!editingItem) {
                updatedItemData.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            } else {
                updatedItemData.color = editingItem.color;
            }

            response = await axiosInstance.post('/flow/boardingflow/anchor', updatedItemData);

            if (response.status === 200) {
                setSuccessMessage(editingItem ? translate('Item successfully updated!') : translate('New item successfully added!'));
                setTimeout(() => setSuccessMessage(''), 2500);

                if (editingItem) {
                    setItems(prevItems => prevItems.map(item =>
                        item.id === editingItem.id ? { ...item, ...updatedItemData } : item
                    ));
                } else {
                    var index = getIndex + 100;
                    setIndex(index);
                    const newItem = { ...updatedItemData, dbid: response.data, id: index };
                    setItems(prevItems => [...prevItems, newItem]);
                }
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            closeModal();
            setEditingItem(null);
        }
    };



    const handleMouseMove = useCallback((e) => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const x = (e.clientX - rect.left) / rect.width;
            setMouseX(x);

            if (draggingItem) {
                const dx = e.clientX - dragStartX;
                const daysDelta = Math.round(dx / 80);

                setItems(prevItems =>
                    prevItems.map(i =>
                        i.id === draggingItem.id
                            ? { ...i, days: Math.max(0, i.days - daysDelta) }
                            : i
                    )
                );

                setDragStartX(e.clientX);
            }
        }
    }, [draggingItem, dragStartX]);

    const handleMouseUp = () => {
        setDraggingItem(null);
    };

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => {
        setIsHovering(false);
        setDraggingItem(null);
    };

    const updateScrollIndicators = useCallback(() => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
        }
    }, []);

    useEffect(() => {
        let animationFrameId;

        const scroll = () => {
            if (isHovering && containerRef.current) {
                const container = containerRef.current;

                const deadZoneStart = 0.3;
                const deadZoneEnd = 0.7;

                let scrollSpeed = 0;

                if (mouseX < deadZoneStart) {
                    scrollSpeed = -1 + (mouseX / deadZoneStart);
                } else if (mouseX > deadZoneEnd) {
                    scrollSpeed = (mouseX - deadZoneEnd) / (1 - deadZoneEnd);
                }

                scrollSpeed = Math.sign(scrollSpeed) * Math.pow(Math.abs(scrollSpeed), 2);

                const maxScrollSpeed = 30;
                const scrollStep = scrollSpeed * maxScrollSpeed;

                container.scrollLeft += scrollStep;
                updateScrollIndicators();

                animationFrameId = requestAnimationFrame(scroll);
            }
        };

        if (isHovering) {
            animationFrameId = requestAnimationFrame(scroll);
        }

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [isHovering, mouseX, updateScrollIndicators]);
     

    const handlePrevious = () => {
        navigate('/boarding/preboarding/employee', { state: { flowid: flowid } });
    };
    const handleCancel = () => {
        navigate('/boarding/preboarding');
    };
     
    const handleSave = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.post('/flow/startboardingflow', inputValues);
            setLoading(false);
          
            if (response.status === 200) { 
                setSuccessMessage(translate('Preboarding flow successfully started!'));
                setTimeout(() => setSuccessMessage(''), 2500);
                navigate('/boarding/preboarding');
            } else {
                throw new Error('Unexpected response status');
            } 
        } catch (err) {
            setLoading(false);
        }
    };



    useEffect(() => {
        updateScrollIndicators();
        window.addEventListener('resize', updateScrollIndicators);
        return () => window.removeEventListener('resize', updateScrollIndicators);
    }, [updateScrollIndicators]);



    const handleDelete = (e) => {
        e.preventDefault();
        setSelectedFlow(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        console.log(selectedFlow);
        if (selectedFlow) {
            await axiosInstance.delete('/flow/remove/' + selectedFlow.id);
            navigate('/boarding/preboarding');
        }
        setIsModalDeleteOpen(false);
    };


    if (loading) return <div>{translate('Loading...')}</div>;
     

            return (
            <>
                {successMessage && (
                    <div
                        className={`success-alert ${isVisible ? 'visible' : ''}`}
                    >
                        <FiInfo /> {successMessage}
                    </div>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <button className="new-button" onClick={openModal} style={{ marginBottom: '0px' }}>{translate('+ Add action')}</button>
                        </div>
                    </div>

                <div className="boarding-container">

                    {canScrollLeft && <div className="scroll-indicator left"></div>}
                    {canScrollRight && <div className="scroll-indicator right"></div>}
                    <div
                        className="boarding-relative"
                        ref={containerRef}
                        onMouseMove={handleMouseMove}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onScroll={updateScrollIndicators}
                    >
                        <div className="boarding-endbar" style={maxDays === 0 ? { right: '40px' } : { left: `${maxDays * 80}px` }}><FaFlagCheckered /></div>

                        {sortedItems
                            .filter(item => !item.isHidden)
                            .map((item, index) => {
                                const isTop = index % 2 === 0;
                                return (
                                    <div
                                        key={item.id}
                                        title={`${item.days} day(s) before`}
                                        className={`boarding-anchor-${isTop ? 'top' : 'bottom'}`}
                                        style={{
                                            color: `${item.color}`,
                                            left: `${((maxDays - item.days) * 80)}px`,
                                            zIndex: item.id === lastMovedItemId ? 10 : 1,
                                            transition: 'left 0.42s ease-in-out'
                                        }}
                                        days={item.days}
                                        onMouseEnter={() => setHoveredItemId(item.id)}
                                        onMouseLeave={() => setHoveredItemId(null)}
                                    >
                                        {hoveredItemId === item.id && item.days >= 0 && item.carriedout === '1900-01-01' && (
                                            <>
                                                <FaArrowLeft onClick={() => moveItem(item.id, 1)} style={{ position: 'absolute', cursor: 'pointer', left: '20px', top: '50%', fontSize: '28px', transform: 'translateY(-50%)' }} />
                                            </>
                                        )}
                                        <AnchorNode
                                            color={item.color}
                                            isTop={isTop}
                                            onMouseDown={(e) => handleMouseDown(e, item)}
                                        />
                                        <div className="content" style={{ borderColor: `${item.color}` }}>
                                            {item.carriedout !== '1900-01-01' && (
                                                <IoCheckmarkOutline className="boarding-anchor-checked" style={{ color: `${item.color}` }} />
                                            )}
                                            {item.name}</div>
                                        {hoveredItemId === item.id && item.carriedout === '1900-01-01' && (
                                            <>
                                                <FaArrowRight onClick={() => moveItem(item.id, -1)} style={{ position: 'absolute', cursor: 'pointer', right: '20px', top: '50%', fontSize: '28px', transform: 'translateY(-50%)' }} />
                                                <FaRegEdit onClick={() => editItem(item.id)} style={{ position: 'absolute', cursor: 'pointer', left: '20px', top: isTop ? '65%' : '20%', fontSize: '28px' }} />
                                                <FaRegTrashAlt onClick={() => deleteItem(item.id)} style={{ position: 'absolute', cursor: 'pointer', left: '18px', top: isTop ? '85%' : '0%', fontSize: '28px' }} />
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>


                <hr />


                <div className="row">
                    <div className="col-md-3">
                        <button onClick={handlePrevious} className="previous-button">{translate('Previous')}</button>
                        <button onClick={handleDelete} className="previous-button">{translate('Delete')}</button>
                    </div>
                    <div className="col-md-9">
                        <button onClick={handleSave} className="save-button">{translate('Start flow')}</button>
                        <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                    </div>
                </div>


                 
                <Modal isOpen={isModalOpen} onClose={closeModal} height="500px" width="1000px">
                        <BoardingflowPopup type={type} onClose={closeModal} editingItem={editingItem} onSubmit={handleSubmit} height="500px" />
                </Modal>
                <ConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={handleDeleteAnchor}
                    title={translate('Remove anchor')}
                    message={translate('Are you sure you want to delete this anchor?')}
                    />
                    <ConfirmationModal
                        isOpen={isModalDeleteOpen}
                        onClose={() => setIsModalDeleteOpen(false)}
                        onConfirm={handleDeleteConfirm}
                        title={translate('Remove flow')}
                        message={translate('Are you sure you want to delete this flow "{{flowName}}"?', { flowName: selectedFlow?.name })}
                    />
            </>
            );
};

export default Preboardingflow;