import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiInfo } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";

const Preboardingemployee = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate, i18n } = useTranslation();
    const [templates, setTemplates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [managers, setManagers] = useState([]);
    const flowid = location.state?.flowid ?? 0;
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isPreferredNameManuallySet, setIsPreferredNameManuallySet] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newNationality, setNewNationality] = useState('');
    const [employeeNrError, setEmployeeNrError] = useState('');
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const [readonlyValues, setReadonlyValues] = useState({
        typeid: 1,
        template: '',  // Changed from 0
        id: '',        // Changed from 0
        startdate: '',
        name: '',
        firstname: '',
        lastname: '',
        preferredname: '',
        gender: '',
        dayofbirth: '',
        placeofbirth: '',
        civilstatus: '',
        personalemail: '',
        personalphone: '',
        nationality: ''
    });
    const [inputValues, setInputValues] = useState({
        currentstep: 2,
        id: '',        // Changed from 0
        employeenr: '',
        jobtitle: '',
        workemail: '',
        department: '', // Changed from 0
        location: '',   // Changed from 0
        manager: ''     // Changed from 0
    });


    const fetchTemplates = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/template/lists');
            setTemplates(response.data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }, []);

    const fetchCountries = useCallback(async () => { 
        try {
            const response = await axiosInstance.get('/country/list/' + i18n.language);
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, []);

    const fetchDepartments = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/departments');
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching department:', error);
        }
    }, []);

    const fetchLocations = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/locations');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }, []);

    const fetchManagers = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/managers');
            setManagers(response.data);
        } catch (error) {
            console.error('Error fetching managers:', error);
        }
    }, []);

    const fetchItem = useCallback(async () => {
        try { 
            setLoading(true);
            const response = await axiosInstance.get('/flow/getboarding/' + flowid);

            // Sanitize data voor readonlyValues
            const sanitizedReadonlyData = Object.fromEntries(
                Object.entries(response.data).map(([key, value]) => [key, value ?? ''])
            );

            // Sanitize data voor inputValues
            const sanitizedInputData = Object.fromEntries(
                Object.entries(response.data).map(([key, value]) => [key, value ?? ''])
            );

            setReadonlyValues(sanitizedReadonlyData);
            setInputValues(sanitizedInputData);
            setActivePage(translate('Preboarding workflow - ' + response.data.name));
        } catch (err) {
            console.error('An error occurred while retrieving the data :', err);
        } finally {
            setLoading(false);
        }
    }, []);

    const validateEmployeeNr = useCallback(async (employeeNr) => {
        if (!employeeNr) {
            setEmployeeNrError('');
            return;
        }

        try {
            const response = await axiosInstance.get('/employee/employeenumber/' + employeeNr);
            if (response.data) {
                setEmployeeNrError(translate('This employee number is already in use'));
            } else {
                setEmployeeNrError('');
            }
        } catch (error) {
            console.error('Error checking employee number:', error);
            setEmployeeNrError(translate('Error checking employee number'));
        }
    }, [translate]);

    useEffect(() => {
        setActivePage(translate('New preboarding'));
        fetchTemplates();
        fetchCountries();
        fetchDepartments();
        fetchLocations();
        fetchManagers();
        if (flowid !== 0) {
            fetchItem();
        } else {
            navigate('/boarding/preboarding');
            setLoading(false);
        }
    }, [setActivePage, translate, fetchTemplates, fetchCountries, fetchDepartments, fetchLocations, fetchManagers, fetchItem, flowid, navigate]);


    const handlePrevious = () => {
        navigate('/boarding/preboarding/details', { state: { flowid: flowid } });
    };

    const handleCancel = () => {
        navigate('/boarding/preboarding');
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'employeenr') {
            validateEmployeeNr(value);
        }
    }, [validateEmployeeNr]);

    const handleSave = async () => {
        try {
            setLoading(true);

            // Convert empty strings back to null for API
            const apiData = Object.fromEntries(
                Object.entries(inputValues).map(([key, value]) => [key, value === '' ? null : value])
            );

            const response = await axiosInstance.post('/flow/setboarding', { ...apiData, currentstep: 2 });
            setLoading(false);

            if (response.status === 200) {
                const flowId = response.data;
                setSuccessMessage(translate('Preboarding flow successfully saved!'));
                setTimeout(() => setSuccessMessage(''), 2500);
                navigate('/boarding/preboarding/flow', { state: { flowid: flowId } });
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (err) {
            setLoading(false);
        }
    };


    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedFlow(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedFlow) {
            await axiosInstance.delete('/flow/remove/' + selectedFlow.id);
            navigate('/boarding/preboarding');
        }
        setIsModalDeleteOpen(false);
    };


    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : ''}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            <div className="right-sidebar col-3">
                <div className="row">
                    <div className="col-12"><strong>{translate('Flow')}</strong></div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Type')}:</span>
                    </div>
                    <div className="col-8">
                        { templates.find(t => t.templateid === readonlyValues.template)?.name }
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Name')}:</span>
                    </div>
                    <div className="col-8">
                        { readonlyValues.name }
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-4">
                        <span className="title">{translate('First day')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.startdate}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"><strong>{translate('Personal details')}</strong></div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Firstname')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.firstname}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Lastname')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.lastname}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Preferred name')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.firstname}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Gender')}</span>
                    </div>
                    <div className="col-8">
                        {(() => {
                            if (readonlyValues.gender === 'Male') {
                                return <>{translate('Male')}</>;
                            } else if (readonlyValues.gender === 'Female') {
                                return <>{translate('Female')}</>;
                            } else {
                                return <>{translate('Other')}</>;
                            }
                        })()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Day of birth')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.dayofbirth}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Place of birth')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.placeofbirth}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Nationality')}:</span>
                    </div>
                    <div className="col-8">
                        {countries.find(t => t.code === readonlyValues.nationality)?.resident} 
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-4">
                        <span className="title">{translate('Civil status')}:</span>
                    </div>
                    <div className="col-8">
                        {(() => {
                            if (readonlyValues.civilstatus === 'Single') {
                                return <>{translate('Single')}</>;
                            } else if (readonlyValues.civilstatus === 'Married') {
                                return <>{translate('Married')}</>;
                            } else if (readonlyValues.civilstatus === 'Divorced') {
                                return <>{translate('Divorced')}</>;
                            } else if (readonlyValues.civilstatus === 'Widowed') {
                                return <>{translate('Widowed')}</>;
                            }  
                        })()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"><strong>{translate('Contact')}</strong></div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Personal email')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.personalemail}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="title">{translate('Phone number')}:</span>
                    </div>
                    <div className="col-8">
                        {readonlyValues.personalphone}
                    </div>
                </div>

            </div>
               
            <div className="row">
                <div className="col-2"><strong>{translate('Employee')}</strong></div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group position-relative">
                                <label htmlFor="employeenr">{translate('Employee nr')}:</label>
                                {employeeNrError && (
                                    <div className="error-message" style={{ color: '#98678f', fontSize: '0.8rem', top: '32px', right: '10px', position: 'absolute' }}>
                                        {employeeNrError}
                                    </div>
                                )}
                                <input
                                    type="text"
                                    maxLength="15"
                                    id="employeenr"
                                    name="employeenr"
                                    value={inputValues.employeenr}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="jobtitle">{translate('Job title')}:</label>
                                <input
                                    type="text"
                                    maxLength="250"
                                    id="jobtitle"
                                    name="jobtitle"
                                    value={inputValues.jobtitle}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="workemail">{translate('Email address')}:</label>
                                <input
                                    type="text"
                                    maxLength="250"
                                    id="workemail"
                                    name="workemail"
                                    value={inputValues.workemail}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="department">{translate('Department')}:</label>
                                <select
                                    value={inputValues.department}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="department"
                                    id="department"
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {departments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="location">{translate('Location')}:</label>
                                <select
                                    value={inputValues.location}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="location"
                                    id="location"
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {locations.map((location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="manager">{translate('Manager')}:</label>
                                <select
                                    value={inputValues.manager}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="manager"
                                    id="manager"
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    <option value="0">{translate('No manager')}</option>
                                    {managers.map((manager) => (
                                        <option key={manager.userid} value={manager.userid}>
                                            {manager.fullname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <button onClick={handlePrevious} className="previous-button">{translate('Previous')}</button>
                    <button onClick={handleDelete} className="previous-button">{translate('Delete')}</button>
                </div>
                <div className="col-md-5">
                    <button onClick={handleSave} className="save-button">{translate('Next')}</button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
            </div>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove flow')}
                message={translate('Are you sure you want to delete this flow "{{flowName}}"?', { flowName: selectedFlow?.name })}
            />
        </>
    );
};

export default Preboardingemployee;