import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import '../../../styling/Configuration.css';
import '../../../styling/Tabs.css';
import { useTranslation } from 'react-i18next';

const Preferences = React.lazy(() => import('./Preferences'));
const Integrations = React.lazy(() => import('./Integrations'));

const Configuration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setActivePage } = useOutletContext();
    const [activeTab, setActiveTab] = useState(0);
    const { t: translate } = useTranslation();

    const tabs = [
        { name: translate('Preferences'), component: Preferences },
        { name: translate('Integrations'), component: Integrations },
        { name: null },
        { name: null }
    ];

    useEffect(() => {
        setActivePage(translate('Configuration')); 

        const params = new URLSearchParams(location.search);
        const tabFromUrl = params.get('tab');
        const tabIndex = tabs.findIndex(tab => tab.name?.toLowerCase() === tabFromUrl);
        setActiveTab(tabIndex !== -1 ? tabIndex : 0);
    }, [location, setActivePage]);

    const handleTabClick = (index) => {
        if(tabs[index].name !== null) {
            setActiveTab(index)
            const tabName = tabs[index].name.toLowerCase();
            navigate(`?tab=${tabName}`, { replace: true });
        }
    };

    return (
        <div>
            <div className="tabs-container">
                <div className="tabs-header">
                    <div className="tabs-list">
                        {tabs.map((tab, index) => ( 
                            <button
                                key={tab.name}
                                className={`tab ${activeTab === index ? 'active' : ''} ${tab.name === null ? 'disabled' : ''}`}
                                disabled={tab.name === null}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab.name}
                            </button> 
                        ))}
                    </div>
                    <div
                        className="tab-indicator"
                        style={{
                            left: `calc(${activeTab * (100 / tabs.length)}% + 10px)`,
                            width: `calc(${100 / tabs.length}% - 20px)`
                        }}
                    ></div>
                </div>
                <div className="tab-content">
                    <React.Suspense fallback={<div>Loading...</div>}>
                        {tabs[activeTab] && React.createElement(tabs[activeTab].component)}
                    </React.Suspense>
                </div>
            </div>
        </div>
    );
};

export default Configuration;