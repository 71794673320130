import React, { useState, useEffect } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { CiSquarePlus } from "react-icons/ci";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Location = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const locationId = location.state?.locationId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: '',
        street: '',
        number: '',
        zipcode: '',
        city: '',
        country: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [countries, setCountries] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [newCountry, setNewCountry] = useState('');
    const { i18n } = useTranslation();

    const fetchCountries = async () => {
        try {
            const response = await axiosInstance.get('/country/list/' + i18n.language);
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        setActivePage(translate('Location'));
        fetchCountries();

        const fetchData = async () => {
            try {
                setLoading(true);
                if (locationId > 0) {
                    const response = await axiosInstance.get('/location/' + locationId);
                    setInputValues(response.data);

                    if (response.data.name.length > 2) {
                        getImage(response.data.name);
                    }
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchData();
    }, [setActivePage, translate, locationId, i18n.language]);

    const getImage = async (value) => {
        if (value.length > 2) {
            try {
                const response = await axiosInstance.get('/location/image/' + value);
                if (response.data && response.data.imageUrl) {
                    setImageUrl(response.data.imageUrl);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
    }

    const handleCancel = (e) => {
        e.preventDefault(); 
        navigate('/organisation/locations');
    };

    const toggleAdding = () => {
        setIsAdding(prev => !prev);
        setNewCountry('');
    };

    const handleAddCountry = (e) => {
        e.preventDefault();
        const trimmedCountry = newCountry.trim();
        if (trimmedCountry) {
            const existingCountry = countries.find(country =>
                country.name.toLowerCase() === trimmedCountry.toLowerCase()
            );
            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, country: existingCountry.code }));
            } else {
                const newCountryCode = Date.now().toString();
                const newCountryObj = { code: newCountryCode, name: trimmedCountry };
                setCountries(prevCountries => [...prevCountries, newCountryObj]);
                setInputValues(prevValues => ({ ...prevValues, country: trimmedCountry }));
            }
            setNewCountry('');
            setIsAdding(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear validation error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }

        if (name === 'name' && value.length > 2) {
            getImage(value);
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Name validation
        if (!inputValues.name?.trim()) {
            errors.name = translate('Location name is required');
            isValid = false;
        } else if (inputValues.name.trim().length < 2) {
            errors.name = translate('Location name must be at least 2 characters long');
            isValid = false;
        } else if (inputValues.name.trim().length > 200) {
            errors.name = translate('Location name cannot be longer than 200 characters');
            isValid = false;
        }

        // Street validation
        if (!inputValues.street?.trim()) {
            errors.street = translate('Street is required');
            isValid = false;
        }

        // House number validation
        if (!inputValues.number?.trim()) {
            errors.number = translate('Number is required');
            isValid = false;
        }

        // Postal code validation
        if (!inputValues.zipcode?.trim()) {
            errors.zipcode = translate('Zipcode is required');
            isValid = false;
        }

        // City validation
        if (!inputValues.city?.trim()) {
            errors.city = translate('City is required');
            isValid = false;
        }

        // Country validation
        if (!inputValues.country?.trim()) {
            errors.country = translate('Country is required');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/location', inputValues);

            const locationId = response.data;
            setSuccessMessage(translate('Location successfully saved!'));
            setLoading(false);
            setTimeout(() => setSuccessMessage(''), 2500);
            navigate('/organisation/location', { state: { locationId: locationId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };

    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedLocation(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedLocation) {
            await axiosInstance.delete('/location/' + selectedLocation.id);
            navigate('/organisation/locations');
        }
        setIsModalDeleteOpen(false);
    };

    function decodeHTMLEntities(text) {
        const element = document.createElement('div');
        element.innerHTML = text;
        return element.textContent;
    }

    const imageContainerStyle = {
        width: '300px',
        height: '300px',
        backgroundImage: imageUrl ? `linear-gradient(90deg, #f5f5f5 15%, rgba(0,212,255,0) 90%), url("${imageUrl}")` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        float: 'right',
        marginTop: '20px',
    };

    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : 'xx'}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div className={`error-alert ${isVisible ? 'visible' : ''}`}>
                    <FiAlertCircle /> {error}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="name">{translate('Name')}:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={inputValues.name}
                                onChange={handleChange}
                                className={`${validationErrors.name ? 'is-invalid' : ''}`}
                                maxLength="200"
                                required
                            />
                            {validationErrors.name && (
                                <div className="invalid-feedback">
                                    {validationErrors.name}
                                </div>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="street">{translate('Street')}:</label>
                                    <input
                                        type="text"
                                        id="street"
                                        name="street"
                                        maxLength="250"
                                        value={inputValues.street}
                                        onChange={handleChange}
                                        className={`${validationErrors.street ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.street && (
                                        <div className="invalid-feedback">
                                            {validationErrors.street}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="number">{translate('Number')}:</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        maxLength="10"
                                        value={inputValues.number}
                                        onChange={handleChange}
                                        className={`${validationErrors.number ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.number && (
                                        <div className="invalid-feedback">
                                            {validationErrors.number}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="zipcode">{translate('Postal Code')}:</label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        maxLength="10"
                                        value={inputValues.zipcode}
                                        onChange={handleChange}
                                        className={`${validationErrors.zipcode ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.zipcode && (
                                        <div className="invalid-feedback">
                                            {validationErrors.zipcode}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="city">{translate('City')}:</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        maxLength="250"
                                        value={inputValues.city}
                                        onChange={handleChange}
                                        className={`${validationErrors.city ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.city && (
                                        <div className="invalid-feedback">
                                            {validationErrors.city}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-group" style={{ position: 'relative' }}>
                            <label htmlFor="country">{translate('Country')}:</label>
                            {!isAdding ? (
                                <div>
                                    <select
                                        value={decodeHTMLEntities(inputValues.country)}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.country ? 'is-invalid' : ''}`}
                                        style={{ width: 'calc(100% - 38px)' }}
                                        name="country"
                                        id="country"
                                        required
                                    >
                                        <option value="">{translate('- Choose -')}</option>
                                        {decodeHTMLEntities(inputValues.country) && Array.isArray(countries) &&
                                            !countries.some(country => decodeHTMLEntities(country.name) === decodeHTMLEntities(inputValues.country)) && inputValues.country !== undefined && (
                                                <option value={decodeHTMLEntities(inputValues.country)}>
                                                    {decodeHTMLEntities(inputValues.country)}
                                                </option>
                                            )}
                                        {Array.isArray(countries) && countries.map((country) => (
                                            <option key={country.id} value={decodeHTMLEntities(country.name)}>
                                                {decodeHTMLEntities(country.name)}
                                            </option>
                                        ))}
                                    </select>
                                    <CiSquarePlus
                                        style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                        onClick={toggleAdding}
                                    />
                                    {validationErrors.country && (
                                        <div className="invalid-feedback">
                                            {validationErrors.country}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <input
                                        type="text"
                                        value={newCountry}
                                        maxLength="200"
                                        onChange={(e) => setNewCountry(e.target.value)}
                                        placeholder={translate('Add option')}
                                        style={{ width: 'calc(100% - 238px)' }}
                                        className={`${validationErrors.country ? 'is-invalid' : ''}`}
                                        autoFocus
                                    />
                                    <button
                                        onClick={handleAddCountry}
                                        className="save-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Add')}
                                    </button>
                                    <button
                                        onClick={toggleAdding}
                                        className="cancel-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Cancel')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                        <div className="col-6">
                            <div style={imageContainerStyle}></div>
                        </div>
                </div>
                    <hr />

                    <div className="row">
                        <div className="col-md-3">
                            {parseInt(inputValues.id) > 0 &&
                                <button type="button" onClick={handleDelete} className="remove-button">
                                    {translate('Delete')}
                                </button>
                            }
                        </div>
                        <div className="col-md-9">
                            <button type="submit" className="save-button">
                                {translate('Save')}
                            </button>
                            <button type="button" onClick={handleCancel} className="cancel-button">
                                {translate('Cancel')}
                            </button>
                        </div>
                    </div>
            </form>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove location')}
                message={translate('Are you sure you want to delete this location "{{locationName}}"?', { locationName: selectedLocation?.name })}
            />
        </>
    );
};

export default Location;