import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axiosInstance from '../../../utils/axiosConfig';
import '../../../styling/Boarding.css';
import Modal from '../../../includes/PopupModal';
import BoardingflowPopup from './Boardingflowpopup';
import { FiPlus, FiInfo } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { FaFlagCheckered } from "react-icons/fa";
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../includes/Tabs';



const Reboardingflow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const templateid = location.state?.templateid ?? 0;
    const { t: translate } = useTranslation();

    const [inputValues, setInputValues] = useState({
        templateid: templateid,
        typeid: 3,
        name: '',
        description: '',
        active: true
    });
    const { setActivePage } = useOutletContext(); 
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible] = useState(false);
    const [selectedAnchor, setSelectedAnchor] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const containerRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [items, setItems] = useState([]);
    const [draggingItem, setDraggingItem] = useState(null);
    const [dragStartX, setDragStartX] = useState(0);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [lastMovedItemId, setLastMovedItemId] = useState(null);
    const [editingItem, setEditingItem] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { name: 'Details' },
        { name: 'Workflow' },
        { name: null },
        { name: null }
    ];


    const AnchorNode = ({ color, isTop, onMouseDown }) => (
        <div onMouseDown={onMouseDown}>
            {isTop ? (
                <svg height="240" width="134" xmlns="http://www.w3.org/2000/svg">
                    <circle r="45" cx="67" cy="50" fill="transparent" stroke="#afafaf" strokeWidth="3" />
                    <circle r="26" cx="67" cy="50" fill="transparent" stroke={color} strokeWidth="3" />
                    <circle r="11" cx="67" cy="50" fill={color} stroke={color} strokeWidth="3" />
                    <path stroke={color} d="M67 224 67 60" strokeWidth="3" />
                    <circle r="5" cx="67" cy="228" fill={color} stroke={color} strokeWidth="3" />
                </svg>
            ) : (
                <svg height="240" width="134" xmlns="http://www.w3.org/2000/svg">
                    <circle r="45" cx="67" cy="190" fill="transparent" stroke="#afafaf" strokeWidth="3" />
                    <circle r="26" cx="67" cy="190" fill="transparent" stroke={color} strokeWidth="3" />
                    <circle r="11" cx="67" cy="190" fill={color} stroke={color} strokeWidth="3" />
                    <path stroke={color} d="M67 12 67 180" strokeWidth="3" />
                    <circle r="5" cx="67" cy="12" fill={color} stroke={color} strokeWidth="3" />
                </svg>
            )}
        </div>
    );

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/template/reboarding/' + inputValues.templateid);
                setInputValues(response.data);
                setItems(response.data.anchors.map((item, index) => ({ ...item, id: index, dbid: item.id, isHidden: false })));
            } catch (err) {
                console.error('An error occurred while retrieving the data :', err);
            } finally {
                setLoading(false);
            }
        };

        if (inputValues.templateid !== 0) {
            fetchItems();
        }
    }, []);

    const sortItems = (itemsToSort) => {
        return [...itemsToSort].sort((a, b) => b.days - a.days);
    };

    const { sortedItems, maxDays, minDays } = useMemo(() => {
        const sorted = sortItems(items);
        const max = sorted.length > 0 ? Math.max(...sorted.map(item => item.days)) : 0;
        const min = sorted.length > 0 ? Math.min(...sorted.map(item => item.days)) : 0;
        return { sortedItems: sorted, maxDays: max, minDays: min };
    }, [items]);

    const offsetLeft = useMemo(() => {
        return Math.max(0, -minDays) * 80;
    }, [minDays]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        // Clear validation error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!inputValues.name?.trim()) {
            errors.name = translate('Name is required');
            isValid = false;
        } else if (inputValues.name.trim().length < 2) {
            errors.name = translate('Name must be at least 2 characters long');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const moveItem = (itemId, direction) => {
        setItems(prevItems =>
            prevItems.map(item => {
                if (item.id === itemId) {
                    const newDays = item.days + direction;
                    return { ...item, days: newDays };
                }
                return item;
            })
        );
        setLastMovedItemId(itemId);

        saveItemChange(itemId, direction);
    };

    const editItem = (itemId) => {
        const item = items.find(item => item.id === itemId);
        setEditingItem(item);
        setIsModalOpen(true);
    };

    const deleteItem = (itemId) => {
        setSelectedAnchor(itemId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteAnchor = useCallback(async () => {
        if (selectedAnchor !== null) {
            try {
                const item = items.find(item => item.id === selectedAnchor);
                if (item) {
                    await axiosInstance.post('/template/deleteanchor', { templateid: inputValues.templateid, anchorid: item.dbid });
                    setItems(prevItems => prevItems.map(i =>
                        i.id === selectedAnchor ? { ...i, isHidden: true } : i
                    ));
                    setSuccessMessage(translate('Anchor successfully removed'));
                    setTimeout(() => setSuccessMessage(''), 2500);
                }
            } catch (error) {
                console.error('Error deleting anchor:', error);
            } finally {
                setSelectedAnchor(null);
                setIsDeleteModalOpen(false);
            }
        }
    }, [selectedAnchor, items, templateid, axiosInstance]);

    const saveItemChange = async (itemId, direction) => {
        try {
            const item = items.find(item => item.id === itemId);
            if (item) {
                const newDays = item.days + direction;

                await axiosInstance.post('/template/anchordays', {
                    templateid: inputValues.templateid,
                    id: item.dbid,
                    days: newDays
                });
                console.log("Item successfully saved");
            }
        } catch (error) {
            console.error("Error saving item:", error);
        }
    };

    const handleMouseDown = (e, item) => {
        setDraggingItem(item);
        setDragStartX(e.clientX);
        setLastMovedItemId(item.id);
    };


    const openModal = (e) => {
        e.preventDefault();
        setEditingItem(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (updatedItemData) => {
        setLoading(true);
        try {
            let response;
            updatedItemData.templateid = inputValues.templateid;

            if (!editingItem) {
                updatedItemData.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            } else {
                updatedItemData.color = editingItem.color;
            }
            response = await axiosInstance.post('/template/anchor', updatedItemData);

            if (response.status === 200) {
                setSuccessMessage(editingItem ? translate('Item successfully updated!') : translate('New item successfully added!'));
                setTimeout(() => setSuccessMessage(''), 2500);

                if (editingItem) {
                    setItems(prevItems => prevItems.map(item =>
                        item.id === editingItem.id ? { ...item, ...updatedItemData } : item
                    ));
                } else {
                    const newItem = { ...updatedItemData, dbid: response.data };
                    setItems(prevItems => [...prevItems, newItem]);
                }
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            closeModal();
            setEditingItem(null);
        }
    };

    const handleMouseMove = useCallback((e) => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const x = (e.clientX - rect.left) / rect.width;
            setMouseX(x);

            if (draggingItem) {
                const dx = e.clientX - dragStartX;
                const daysDelta = Math.round(dx / 80);

                setItems(prevItems =>
                    prevItems.map(i =>
                        i.id === draggingItem.id
                            ? { ...i, days: Math.max(0, i.days - daysDelta) }
                            : i
                    )
                );

                setDragStartX(e.clientX);
            }
        }
    }, [draggingItem, dragStartX]);

    const handleMouseUp = () => {
        setDraggingItem(null);
    };

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => {
        setIsHovering(false);
        setDraggingItem(null);
    };

    const updateScrollIndicators = useCallback(() => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
        }
    }, []);

    useEffect(() => {
        let animationFrameId;

        const scroll = () => {
            if (isHovering && containerRef.current) {
                const container = containerRef.current;

                const deadZoneStart = 0.3;
                const deadZoneEnd = 0.7;

                let scrollSpeed = 0;

                if (mouseX < deadZoneStart) {
                    scrollSpeed = -1 + (mouseX / deadZoneStart);
                } else if (mouseX > deadZoneEnd) {
                    scrollSpeed = (mouseX - deadZoneEnd) / (1 - deadZoneEnd);
                }

                scrollSpeed = Math.sign(scrollSpeed) * Math.pow(Math.abs(scrollSpeed), 2);

                const maxScrollSpeed = 30;
                const scrollStep = scrollSpeed * maxScrollSpeed;

                container.scrollLeft += scrollStep;
                updateScrollIndicators();

                animationFrameId = requestAnimationFrame(scroll);
            }
        };

        if (isHovering) {
            animationFrameId = requestAnimationFrame(scroll);
        }

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [isHovering, mouseX, updateScrollIndicators]);

    useEffect(() => {
        updateScrollIndicators();
        window.addEventListener('resize', updateScrollIndicators);
        return () => window.removeEventListener('resize', updateScrollIndicators);
    }, [updateScrollIndicators]);


    const handleSave = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/template/reboarding', inputValues);
            setLoading(false);

            if (response.status === 200) {
                setSuccessMessage(translate('Reboarding flow successfully saved!'));
                setTimeout(() => setSuccessMessage(''), 2500);
                navigate('/settings/boarding/reboardingflow', { state: { templateid: response.data } });
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/settings/boarding?tab=' + translate('re boarding'));
    };

    const handleDelete = () => {
        console.log(inputValues);
        setSelectedFlow(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedFlow) {
            console.log(`Flow ${selectedFlow.name} is being deleted`);
            await axiosInstance.post('/template/removeflow', { templateid: selectedFlow.templateid });
            navigate('/settings/boarding?tab=' + translate('re boarding'));
        }
        setIsModalDeleteOpen(false);
    };

    useEffect(() => {
        setActivePage(translate('Reboarding'));
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }


    return (
        <>
            {successMessage && (
                <div
                    className={`success-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiInfo /> {successMessage}
                </div>
            )}
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            <form onSubmit={handleSave}>
                {activeTab === 0 && (
                    <div className="tab-content">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="name">{translate('Name')}</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={inputValues.name}
                            onChange={handleChange}
                            placeholder={translate('Workflow name')}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="Description">{translate('Description')}</label>
                        <textarea
                            name="description"
                            id="description"
                            rows="8"
                            onChange={handleChange}
                            placeholder={translate('Workflow description')}
                            className="form-control"
                            value={inputValues.description ?? ''}
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-3 mt-0">
                <div className="col-md-6">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="active"
                            name="active"
                            checked={inputValues.active}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="active">{translate('Active')}</label>
                    </div>
                </div>
            </div> 
                    </div>
                )}

                {activeTab === 1 && (
                    <>
 

            <div className="boarding-container">
                    {inputValues.templateid > 0 && ( 
                                <button className="add-button" onClick={openModal}>+</button> 
                    )}
                {canScrollLeft && <div className="scroll-indicator left"></div>}
                {canScrollRight && <div className="scroll-indicator right"></div>}
                <div
                    className="boarding-relative"
                    ref={containerRef}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onScroll={updateScrollIndicators}
                >
                    <div className="boarding-midbar" style={{ left: `${offsetLeft}px` }}><FaFlagCheckered /></div>

                    {sortedItems
                        .filter(item => !item.isHidden)
                        .map((item, index) => {
                        const isTop = index % 2 === 0;
                        return (
                            <div
                                key={item.id}
                                title={`${Math.abs(item.days)} day(s) ${item.days >= 0 ? 'after' : 'before'}`}
                                className={`boarding-anchor-${isTop ? 'top' : 'bottom'}`}
                                style={{
                                    color: `${item.color}`,
                                    left: `${offsetLeft + item.days * 80}px`,
                                    zIndex: item.id === lastMovedItemId ? 10 : 1,
                                    transition: 'left 0.42s ease-in-out'
                                }}
                                days={item.days}
                                onMouseEnter={() => setHoveredItemId(item.id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                            >
                                 
                                <AnchorNode
                                    color={item.color}
                                    isTop={isTop}
                                    onMouseDown={(e) => handleMouseDown(e, item)}
                                />
                                <div className="content" style={{ borderColor: `${item.color}` }}>{item.name}</div>
                                {hoveredItemId === item.id && (
                                    <>
                                            <FaArrowLeft onClick={() => moveItem(item.id, -1)} style={{ position: 'absolute', cursor: 'pointer', left: '20px', top: '50%', fontSize: '28px', transform: 'translateY(-50%)' }} />
                                    <FaArrowRight onClick={() => moveItem(item.id, 1)} style={{ position: 'absolute', cursor: 'pointer', right: '20px', top: '50%', fontSize: '28px', transform: 'translateY(-50%)' }} />
                                        <FaRegEdit onClick={() => editItem(item.id)} style={{ position: 'absolute', cursor: 'pointer', left: '20px', top: isTop ? '65%' : '20%', fontSize: '28px' }} />
                                        <FaRegTrashAlt onClick={() => deleteItem(item.id)} style={{ position: 'absolute', cursor: 'pointer', left: '18px', top: isTop ? '85%' : '0%', fontSize: '28px' }} />
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
                        </div>
                    </>
                )}

                <hr className="mt-5" />
            <div className="row">
                <div className="col-md-3">
                    {inputValues.templateid !== 0 &&
                        <button onClick={handleDelete} className="remove-button">{translate('Delete')}</button>
                    }
                </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
                </div>
            </form>
            <Modal isOpen={isModalOpen} onClose={closeModal} height="500px" width="1000px">
                <BoardingflowPopup type={inputValues.typeid} onClose={closeModal} editingItem={editingItem} onSubmit={handleSubmit} height="500px" />
            </Modal>
            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteAnchor}
                title={translate('Remove anchor')}
                message={translate('Are you sure you want to delete this anchor?')}
            />
            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove flow')}
                message={translate('Are you sure you want to delete this flow "{{flowName}}"?', { flowName: selectedFlow?.name })}
            />
        </>
    );
}

export default Reboardingflow;