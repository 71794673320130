import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";

const Personaldetails = ({ onSaveSuccess }) => {  
    const location = useLocation();
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate, i18n } = useTranslation(); 
    const [countries, setCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const flowid = location.state?.flowid ?? 0;
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isPreferredNameManuallySet, setIsPreferredNameManuallySet] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newNationality, setNewNationality] = useState('');
    const [isAdding2, setIsAdding2] = useState(false);
    const [newCountry, setNewCountry] = useState('');
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedFlow, setSelectedFlow] = useState(null);

    const [inputValues, setInputValues] = useState({
        currentstep: 1,
        typeid: 1,
        template: '',
        id: 0,
        startdate: '',
        name: '',
        firstname: '',
        lastname: '',
        preferredname: '',
        gender: '',
        dayofbirth: '',
        placeofbirth: '',
        civilstatus: '',
        personalemail: '',
        personalphone: '',
        nationality: '',
    });
     

    const fetchCountries = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/country/list/' + i18n.language);
            setCountries(response.data);
            setNationalities(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, [i18n.language]);

    const fetchItem = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/flow/getboarding/' + flowid);

            // Convert null values to empty strings before setting state
            const sanitizedData = Object.fromEntries(
                Object.entries(response.data).map(([key, value]) => [key, value ?? ''])
            );

            setInputValues(sanitizedData);
            setActivePage(translate('Preboarding workflow - ' + response.data.name));
        } catch (err) {
            console.error('An error occurred while retrieving the data :', err);
        } finally {
            setLoading(false);
        }
    }, [flowid, setActivePage, translate]);

    useEffect(() => {
        setActivePage(translate('Edit details')); 
        fetchCountries();
        if (flowid !== 0) {
            fetchItem();
        } else {
            setLoading(false);
        }
    }, [setActivePage, translate, fetchCountries, fetchItem, flowid]);


    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'preferredname') {
            setIsPreferredNameManuallySet(value !== '');
        }
    }, []);



    useEffect(() => {
        if (!isPreferredNameManuallySet) {
            const { firstname, lastname } = inputValues;
            let autoPreferredName = '';
            if (firstname && lastname) {
                autoPreferredName = `${firstname} ${lastname}`.trim();
            }
            setInputValues(prevState => ({
                ...prevState,
                preferredname: autoPreferredName
            }));
        }
    }, [inputValues.firstname, inputValues.lastname, isPreferredNameManuallySet]);



    // First, add this email validation regex near the top of your component
    const validateForm = () => {
        const errors = {};
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?[\d\s-]{6,14}$/;

        // Personal details validation
        if (!inputValues.firstname?.trim()) {
            errors.firstname = translate('First name is required');
            isValid = false;
        }

        if (!inputValues.lastname?.trim()) {
            errors.lastname = translate('Last name is required');
            isValid = false;
        }
        /*
        if (!inputValues.preferredname?.trim()) {
            errors.preferredname = translate('Preferred name is required');
            isValid = false;
        }

        if (!inputValues.gender) {
            errors.gender = translate('Gender is required');
            isValid = false;
        }

        // Address validation
        if (!inputValues.street?.trim()) {
            errors.street = translate('Street is required');
            isValid = false;
        }

        if (!inputValues.number?.trim()) {
            errors.number = translate('House number is required');
            isValid = false;
        }

        // Birth and nationality validation
        if (!inputValues.dayofbirth?.trim()) {
            errors.dayofbirth = translate('Date of birth is required');
            isValid = false;
        } else {
            const birthDate = new Date(inputValues.dayofbirth);
            const today = new Date();
            if (birthDate > today) {
                errors.dayofbirth = translate('Date of birth cannot be in the future');
                isValid = false;
            }
        }

        if (!inputValues.placeofbirth?.trim()) {
            errors.placeofbirth = translate('Place of birth is required');
            isValid = false;
        }

        if (!inputValues.nationality) {
            errors.nationality = translate('Nationality is required');
            isValid = false;
        }

        if (!inputValues.civilstatus) {
            errors.civilstatus = translate('Civil status is required');
            isValid = false;
        }

        // Contact validation
        if (!inputValues.personalemail?.trim()) {
            errors.personalemail = translate('Personal email is required');
            isValid = false;
        } else if (!emailRegex.test(inputValues.personalemail)) {
            errors.personalemail = translate('Please enter a valid email address');
            isValid = false;
        }

        if (inputValues.personalphone?.trim()) {
            if (!phoneRegex.test(inputValues.personalphone)) {
                errors.personalphone = translate('Please enter a valid phone number');
                isValid = false;
            }
        }
        */

        setValidationErrors(errors);
        return isValid;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);

            // Convert empty strings back to null for the API
            const apiData = Object.fromEntries(
                Object.entries(inputValues).map(([key, value]) => [key, value === '' ? null : value])
            );

            const response = await axiosInstance.post('/flow/personaldetails', { ...apiData });
            setLoading(false);


            if (response.status === 200) {
                const flowId = response.data;
                setSuccessMessage(translate('Preboarding flow successfully saved!'));
                setIsVisible(true);

                if (onSaveSuccess) {
                    onSaveSuccess();
                }

                setTimeout(() => {
                    setSuccessMessage('');
                    setIsVisible(false);
                }, 2500);
                //navigate('/boarding/preboarding/employee', { state: { flowid: flowId } });
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (err) {
            console.error('Error saving preboarding flow:', err);
            setLoading(false);
        }
    }


    const handleAddNationality = useCallback(() => {
        const trimmedNationality = newNationality.trim();
        if (trimmedNationality) {
            const existingCountry = nationalities.find(country =>
                country.resident.toLowerCase() === trimmedNationality.toLowerCase()
            );
            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, nationality: existingCountry.code }));
            } else {
                const newCountryCode = Date.now().toString();
                const newCountry = { code: newCountryCode, resident: trimmedNationality };
                setNationalities(prevCountries => [...prevCountries, newCountry]);
                setInputValues(prevValues => ({ ...prevValues, nationality: newCountryCode }));
            }
            setNewNationality('');
            setIsAdding(false);
        }
    }, [nationalities, newNationality]);

    const toggleAdding = useCallback(() => {
        setIsAdding(prev => !prev);
        setNewNationality('');
    }, []);
    const toggleAdding2 = useCallback(() => {
        setIsAdding2(prev => !prev);
        setNewCountry('');
    }, []);

    function decodeHTMLEntities(text) {
        const element = document.createElement('div');
        element.innerHTML = text;
        return element.textContent;
    }

    const handleCancel = () => { 
        onSaveSuccess();
    }

    const handleAddCountry = (e) => {
        e.preventDefault();
        const trimmedCountry = newCountry.trim();
        if (trimmedCountry) {
            const existingCountry = countries.find(country =>
                country.name.toLowerCase() === trimmedCountry.toLowerCase()
            );
            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, country: existingCountry.code }));
            } else {
                const newCountryCode = Date.now().toString();
                const newCountryObj = { code: newCountryCode, name: trimmedCountry };
                setCountries(prevCountries => [...prevCountries, newCountryObj]);
                setInputValues(prevValues => ({ ...prevValues, country: trimmedCountry }));
            }
            setNewCountry('');
            setIsAdding2(false);
        }
    };

    if (loading) return <div>{translate('Loading...')}</div>;


    return (
        <div style={{ height: '470px', width: '970px', overflowX: 'auto' }}>
            <form onSubmit={handleSubmit}> 
                <div className="row m-0">
                    <div className="col-3"><strong>{translate('Personal details')}</strong></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="firstname">{translate('Firstname')}:</label>
                                    <input
                                        type="text"
                                        maxLength="200"
                                        id="firstname"
                                        name="firstname"
                                        value={inputValues.firstname}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="lastname">{translate('Lastname')}:</label>
                                    <input
                                        type="text"
                                        maxLength="200"
                                        id="lastname"
                                        name="lastname"
                                        value={inputValues.lastname}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="preferredname">{translate('Preferred name')}:</label>
                                    <input
                                        type="text"
                                        maxLength="200"
                                        id="preferredname"
                                        name="preferredname"
                                        value={inputValues.preferredname}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="gender">{translate('Gender')}</label>
                                    <select className="form-control" id="gender" name="gender" value={inputValues.gender} onChange={handleChange}>
                                        <option key="gender-choose" value="">{translate('- Choose -')}</option>
                                        <option key="gender-male" value="Male">{translate('Male')}</option>
                                        <option key="gender-female" value="Female">{translate('Female')}</option>
                                        <option key="gender-other" value="Other">{translate('Other')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="dayofbirth">{translate('Day of birth')}:</label>
                                    <input
                                        type="date"
                                        id="dayofbirth"
                                        name="dayofbirth"
                                        maxLength="10"
                                        value={inputValues.dayofbirth}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="form-group">
                                    <label htmlFor="placeofbirth">{translate('Place of birth')}:</label>
                                    <input
                                        type="text"
                                        id="placeofbirth"
                                        name="placeofbirth"
                                        maxLength="200"
                                        value={inputValues.placeofbirth}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group" style={{ position: 'relative' }} >
                                    <label htmlFor="nationality">{translate('Nationality')}:</label>
                                    {!isAdding ? (
                                        <div>
                                            <select
                                                value={inputValues.nationality}
                                                onChange={handleChange}
                                                className="form-control"
                                                style={{ width: 'calc(100% - 38px)' }}
                                                name="nationality"
                                                id="nationality"
                                            >
                                                <option value="">{translate('- Choose -')}</option>
                                                {nationalities.map((country) => (
                                                    <option key={country.code} value={country.code}>
                                                        {country.resident}
                                                    </option>
                                                ))}
                                            </select>
                                            <CiSquarePlus
                                                style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                                onClick={toggleAdding}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                value={newNationality}
                                                maxLength="200"
                                                onChange={(e) => setNewNationality(e.target.value)}
                                                placeholder={translate('Add option')}
                                                style={{ width: 'calc(100% - 228px)' }}
                                                autoFocus
                                            />
                                            <button
                                                onClick={handleAddNationality}
                                                className="save-button"
                                                style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                            >
                                                {translate('Add')}
                                            </button>
                                            <button
                                                onClick={toggleAdding}
                                                className="cancel-button"
                                                style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                            >
                                                {translate('Cancel')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="civilstatus">{translate('Civil status')}</label>
                                    <select className="form-control" id="civilstatus" name="civilstatus" value={inputValues.civilstatus} onChange={handleChange}>
                                        <option key="civil-choose" value="">{translate('- Choose -')}</option>
                                        <option key="civil-single" value="Single">{translate('Single')}</option>
                                        <option key="civil-married" value="Married">{translate('Married')}</option>
                                        <option key="civil-divorced" value="Divorced">{translate('Divorced')}</option>
                                        <option key="civil-widowed" value="Widowed">{translate('Widowed')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row m-0">

                    <div className="col-3"><strong>{translate('Address')}</strong></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="street">{translate('Street')}:</label>
                                    <input
                                        type="text"
                                        id="street"
                                        name="street"
                                        maxLength="250"
                                        value={inputValues.street}
                                        onChange={handleChange}
                                        className={`${validationErrors.street ? 'is-invalid' : ''}`}
                                    />
                                    {validationErrors.street && (
                                        <div className="invalid-feedback">
                                            {validationErrors.street}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="number">{translate('Number')}:</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        maxLength="10"
                                        value={inputValues.number}
                                        onChange={handleChange}
                                        className={`${validationErrors.number ? 'is-invalid' : ''}`}
                                    />
                                    {validationErrors.number && (
                                        <div className="invalid-feedback">
                                            {validationErrors.number}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="zipcode">{translate('Postal Code')}:</label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        maxLength="10"
                                        value={inputValues.zipcode}
                                        onChange={handleChange}
                                        className={`${validationErrors.zipcode ? 'is-invalid' : ''}`}
                                    />
                                    {validationErrors.zipcode && (
                                        <div className="invalid-feedback">
                                            {validationErrors.zipcode}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="city">{translate('City')}:</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        maxLength="250"
                                        value={inputValues.city}
                                        onChange={handleChange}
                                        className={`${validationErrors.city ? 'is-invalid' : ''}`}
                                    />
                                    {validationErrors.city && (
                                        <div className="invalid-feedback">
                                            {validationErrors.city}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className="form-group mb-5" style={{ position: 'relative' }}>
                            <label htmlFor="country">{translate('Country')}:</label>
                            {!isAdding2 ? (
                                <div>
                                    <select
                                        value={decodeHTMLEntities(inputValues.country)}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.country ? 'is-invalid' : ''}`}
                                        style={{ width: 'calc(100% - 38px)' }}
                                        name="country"
                                        id="country"
                                    >
                                        <option value="">{translate('- Choose -')}</option>
                                        {decodeHTMLEntities(inputValues.country) && Array.isArray(countries) &&
                                            !countries.some(country => decodeHTMLEntities(country.name) === decodeHTMLEntities(inputValues.country)) &&
                                            inputValues.country !== undefined && (
                                                <option
                                                    key={`country-${inputValues.country}`}
                                                    value={decodeHTMLEntities(inputValues.country)}
                                                >
                                                    {decodeHTMLEntities(inputValues.country)}
                                                </option>
                                            )}
                                        {Array.isArray(countries) && countries.map((country, index) => (
                                            <option
                                                key={country.code || `country-list-${index}`}
                                                value={decodeHTMLEntities(country.name)}
                                            >
                                                {decodeHTMLEntities(country.name)}
                                            </option>
                                        ))}
                                    </select>
                                    <CiSquarePlus
                                        style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                        onClick={toggleAdding2}
                                    />
                                    {validationErrors.country && (
                                        <div className="invalid-feedback">
                                            {validationErrors.country}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <input
                                        type="text"
                                        value={newCountry}
                                        maxLength="200"
                                        onChange={(e) => setNewCountry(e.target.value)}
                                        placeholder={translate('Add option')}
                                        style={{ width: 'calc(100% - 238px)' }}
                                        className={`${validationErrors.country ? 'is-invalid' : ''}`}
                                        autoFocus
                                    />
                                    <button
                                        onClick={handleAddCountry}
                                        className="save-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Add')}
                                    </button>
                                    <button
                                        onClick={toggleAdding2}
                                        className="cancel-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Cancel')}
                                    </button>
                                </div>
                            )}
                        </div>



                        <hr />
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-3"><strong>{translate('Contact')}</strong></div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="personalemail">{translate('Personal email')}:</label>
                                    <input
                                        type="email"
                                        maxLength="200"
                                        id="personalemail"
                                        name="personalemail"
                                        value={inputValues.personalemail}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="personalphone">{translate('Phone number')}:</label>
                                    <input
                                        type="text"
                                        maxLength="14"
                                        id="personalphone"
                                        name="personalphone"
                                        value={inputValues.personalphone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-md-11">
                        <button type="submit" className="save-button">{translate('Save')}</button>
                        <button type="button" className="cancel-button" onClick={handleCancel}>{translate('cancel')}</button>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default Personaldetails;