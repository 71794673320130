import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../utils/axiosConfig';
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const Language = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const languageId = location.state?.languageId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: '',
        code: '',
        active: true
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setActivePage(translate('Language'));

        const fetchData = async () => {
            try {
                setLoading(true);
                if (languageId > 0) {
                    const response = await axiosInstance.get('/language/' + languageId);
                    setInputValues(response.data);
                }
                setLoading(false);
            } catch (err) { 
                setLoading(false);
            }
        };

        if (languageId > 0) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [setActivePage, translate, languageId]);

    const handleTranslationUpdate = useCallback(async (row, newTranslation) => {
        try {
            await axiosInstance.post(`/languages/item`, {
                languageid: languageId,
                id: row.id,
                translation: newTranslation
            });

            setData(prevData => prevData.map(item =>
                item.id === row.id ? { ...item, translation: newTranslation } : item
            ));

            setSuccessMessage(translate('Translation updated successfully!'));
            setTimeout(() => setSuccessMessage(''), 2500);
        } catch (err) {
            console.error("Error updating translation:", err);
            setError(translate('Failed to update translation. Please try again.'));
        }
    }, [languageId]);

    const handleTranslationChange = useCallback((row, event) => {
        const newTranslation = event.target.value;
        setData(prevData => prevData.map(item =>
            item.id === row.id ? { ...item, translation: newTranslation } : item
        ));
    }, []);

    const handleTranslationBlur = useCallback((row, event) => {
        handleTranslationUpdate(row, event.target.value);
    }, [handleTranslationUpdate]);

    const handleTranslationKeyPress = useCallback((row, event) => {
        if (event.key === 'Enter') {
            handleTranslationUpdate(row, event.target.value);
        }
    }, [handleTranslationUpdate]);

    const columns = [
        {
            name: translate('Item'),
            selector: row => row.name,
            sortable: true,
            reorder: false,
            width: '300px',
        },
        {
            name: translate('Translation'),
            cell: row => (
                <input
                    type="text"
                    value={row.translation || ''}
                    onChange={(e) => handleTranslationChange(row, e)}
                    onBlur={(e) => handleTranslationBlur(row, e)}
                    onKeyPress={(e) => handleTranslationKeyPress(row, e)}
                    className="form-control"
                />
            ),
            sortable: false,
            reorder: false,
        },
    ];

    const handleCancel = (e) => {
        e.preventDefault(); 
        navigate('/settings/languages');
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!inputValues.name?.trim()) {
            errors.name = translate('Language name is required');
            isValid = false;
        } else if (inputValues.name.trim().length < 2) {
            errors.name = translate('Language name must be at least 2 characters long');
            isValid = false;
        } else if (inputValues.name.trim().length > 200) {
            errors.name = translate('Language name cannot be longer than 200 characters');
            isValid = false;
        }

        if (!inputValues.code?.trim()) {
            errors.code = translate('Language code is required');
            isValid = false;
        } else if (inputValues.code.trim().length !== 2) {
            errors.code = translate('Language code must be exactly 2 characters');
            isValid = false;
        } else if (!/^[a-zA-Z]{2}$/.test(inputValues.code)) {
            errors.code = translate('Language code must contain only letters');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/language', inputValues);

            const languageId = response.data;
            setSuccessMessage(translate('Language successfully saved!'));
            setTimeout(() => setSuccessMessage(''), 2500);
            setLoading(false);
            navigate('/settings/language', { state: { languageId: languageId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };


    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedLanguage(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedLanguage) {
            console.log(`Language ${selectedLanguage.name} is being deleted`);
            await axiosInstance.delete('/language/' + selectedLanguage.id);   
            navigate('/settings/languages');
        }
        setIsModalDeleteOpen(false);
    };

    useEffect(() => {
        setActivePage(translate('Languages'));

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/languages/' + languageId + '/items');
                setData(response.data); 
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);


    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div
                    className={`success-alert ${isVisible ? 'visible' : 'xx'}`}
                >
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div
                    className={`error-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiAlertCircle /> {error}
                </div>
            )}
            <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="name">{translate('Name')}:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            maxLength="200"
                            value={inputValues.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="code">{translate('Code')}:</label>
                        <input
                            type="text"
                            id="code"
                            maxLength="2"
                            name="code"
                            value={inputValues.code}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="active"
                            name="active"
                            checked={inputValues.active}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="active">{translate('Active')}</label>
                    </div>
                </div>
            </div>

            <hr />

            <div className="row mt-5">
                <div className="col-12">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={8}
                        paginationRowsPerPageOptions={[8]}
                        paginationResetDefaultPage={resetPaginationToggle}
                        persistTableHead
                        striped
                        highlightOnHover
                    />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-3">
                    {parseInt(inputValues.id) > 1 &&
                        <button onClick={handleDelete} className="remove-button">{translate('Delete')}</button>
                    }
                </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
                </div>
            </form>
            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove language')}
                message={translate('Are you sure you want to delete this language "{{languageName}}"?', { languageName: selectedLanguage?.name })}
            />
        </>
    );
};

export default Language;