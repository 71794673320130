import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { GoTasklist } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { GrStatusInfo } from "react-icons/gr";
import { useTranslation } from 'react-i18next';
import '../styling/Tasks.css';

const Tasks = () => {
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [tasks, setTasks] = useState([]);

    const typeNames = {
        1: "Preboarding",
        2: "Onboarding",
        3: "Reboarding",
        4: "Offboarding"
    };

    useEffect(() => {
        setActivePage(translate('Tasks'));
    }, [setActivePage, translate]);

    const fetchTasks = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/tasks');
            setTasks(response.data || []); // Zorg ervoor dat we altijd een array hebben
        } catch (error) {
            console.error('Error fetching tasks:', error);
            setTasks([]); // Zet tasks naar een lege array bij een error
        }
    }, []);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleTaskClick = (flowId) => {
        navigate('/boarding/preboarding/overview', {
            state: { flowid: flowId }
        });
    };

    if (!tasks || tasks.length === 0) {
        return (
            <div className="empty-state">
                <GoTasklist className="task-listicon" />
                <p>{translate('No Tasks found')}</p>
            </div>
        );
    }

    const groupedTasks = (tasks || []).reduce((groups, task) => {
        const group = groups[task.typeid] || [];
        group.push(task);
        groups[task.typeid] = group;
        return groups;
    }, {});

    return (
        <div className="tasks-container">
            {Object.entries(groupedTasks).map(([typeId, typeTasks]) => (
                <div key={typeId} className="task-group">
                    <h4 className="type-title">{typeNames[typeId] || `Type ${typeId}`}</h4>
                    {typeTasks.map((task) => (
                        <div className="row" key={`row-${task.id}`}>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-5 ps-5">
                                        <div className="task-item" style={{ borderColor: 'transparent' }}>
                                            <div className="icon-space">
                                                <BiTask style={{ color: '#666', marginTop: '2px', marginLeft: '-20px' }} />
                                            </div>
                                            <div className="task-content">
                                                <div className="task-header">
                                                    <span className="task-space-task">
                                                        <button
                                                            onClick={() => handleTaskClick(task.flowid)}
                                                            className="action-button"
                                                            style={{ float: 'right' }}
                                                        >
                                                            {task.name}
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <span className="task-space-date">
                                            {task.startdate}
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="task-space-date">
                                            {task.performdays} day(s) to complete this task
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Tasks;