import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import { FaChevronLeft, FaChevronRight, FaExclamationTriangle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { actionTypes } from '../../includes/actionTypes';

const BoardingflowPopup = ({ type, onClose, onSubmit, editingItem, height = "200px" }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [buttonsEnabled, setButtonsEnabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);
    const [messages, setMessages] = useState([]);
    const [laststep, setLastStep] = useState(false);
    const { t: translate } = useTranslation();

    const [formData, setFormData] = useState({
        id: 0,
        dbid: 0,
        name: '',
        description: '',
        days: '',
        performdays: '',
        carriedoutby: 'intern',
        employee: '',
        actiontype: '',
        action: '',
        task: '',
        slackmessage: '',
        teammessage: '',
        message: '',
    });

    const modalStyle = {
        height: `calc(${height} - 40px)`
    };

    useEffect(() => {
        if (editingItem) {
            setFormData({
                id: editingItem.id,
                dbid: editingItem.dbid,
                name: editingItem.name || '',
                description: editingItem.description || '',
                days: editingItem.days !== null ? parseInt(editingItem.days) : 0,
                performdays: editingItem.performdays !== null ? parseInt(editingItem.performdays) : 0,
                carriedoutby: editingItem.carriedoutby || 'intern',
                employee: editingItem.employee ? editingItem.employee.toString() : '',
                actiontype: editingItem.actiontype,
                task: editingItem.actiontype === 'tsk' || editingItem.actiontype === 'emp' ? editingItem.action : '',
                slackmessage: editingItem.actiontype === 'slk' ? editingItem.action : '',
                teammessage: editingItem.actiontype === 'tms' ? editingItem.action : '',
                action: editingItem.action || '',
                message: editingItem.message !== null ? editingItem.message.toString() : '0',
            });
            setLastStep(false);
        }
    }, [editingItem]);

    const steps = [
        {
            id: 1,
            title: translate('Basic Information'),
            fields: [
                { name: 'name', label: translate('Name'), errorMessage: translate('Please enter a name for this action') },
                { name: 'description', label: translate('Description'), errorMessage: null },
            ],
        },
        {
            id: 2,
            title: translate('Timing'),
            fields: [
                { name: 'days', label: translate('Days before first working day'), errorMessage: translate('Please specify the number of days before the first working day') },
                { name: 'performdays', label: translate('Days to perform this action'), errorMessage: translate('Please specify how many days are needed to perform this action') },
            ],
        },
        {
            id: 3,
            title: translate('Responsibility'),
            fields: [
                { name: 'carriedoutby', label: translate('Carried out by'), errorMessage: translate('Please select who will carry out this action') },
            ],
        },
        {
            id: 4,
            title: translate('Employee'),
            fields: [
                { name: 'employee', label: translate('Choose employee'), errorMessage: translate('Please select a employee') },
            ],
            condition: () => formData.carriedoutby === 'intern'
        },
        {
            id: 5,
            title: translate('Action type'),
            fields: [
                { name: 'actiontype', label: translate('Select the task type'), errorMessage: translate('Please select a task type') },
            ],
            condition: () => formData.carriedoutby !== 'system'  // Voor niet-system gebruikers
        },
        {
            id: 6,
            title: translate('Action type'),
            fields: [
                { name: 'actiontype', label: translate('Select an action'), errorMessage: translate('Please select an action') },
            ],
            condition: () => formData.carriedoutby === 'system'  // Voor system gebruikers
        },
        {
            id: 7,
            title: translate('Task details'),
            fields: [
                { name: 'task', label: translate('The task to be performed'), errorMessage: translate('Please enter the task to be performed') },
            ],
            condition: () => formData.carriedoutby !== 'system' && formData.actiontype === 'tsk'
        },
        {
            id: 8,
            title: translate('Slack message'),
            fields: [
                { name: 'slackmessage', label: translate('The message to be sent'), errorMessage: translate('Please enter the message that should be sent') },
            ],
            condition: () => formData.carriedoutby === 'system' && formData.actiontype === 'slk'
        },
        {
            id: 9,
            title: translate('Team message'),
            fields: [
                { name: 'teammessage', label: translate('The message to be sent'), errorMessage: translate('Please enter the message that should be sent') },
            ],
            condition: () => formData.carriedoutby === 'system' && formData.actiontype === 'tms'
        },
        {
            id: 10,
            title: translate('Message'),
            fields: [
                { name: 'message', label: translate('Select the message'), errorMessage: translate('Please select a message to be sent') },
            ],
            condition: () => {
                if (formData.carriedoutby === 'system') {
                    return formData.actiontype === 'eml';
                }
                // Voor niet-system gebruikers altijd een message tonen na task of sec
                return ['tsk', 'emp', 'sec', 'fpd', 'iys', 'sci'].includes(formData.actiontype);
            }
        }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const validateStep = () => {
        const currentFields = steps[currentStep].fields;
        const newErrors = {};
        currentFields.forEach(field => {
            if (field.name === 'days' || field.name === 'performdays' || field.name === 'message') {
                if (formData[field.name] === '' || isNaN(parseInt(formData[field.name]))) {
                    newErrors[field.name] = field.errorMessage;
                }
            } else if (!formData[field.name] && formData[field.name] !== 0 && field.errorMessage != null) {
                newErrors[field.name] = field.errorMessage;
            }
        });

        if (currentStep === steps.length - 1 && !formData.message) {
            newErrors.message = translate('Please select a message to be sent');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (buttonsEnabled && validateStep()) {
            let action = null;

            switch (formData.actiontype) {
                case 'tsk':
                    action = formData.task;
                    break;
                case 'slk':
                    action = formData.slackmessage;
                    formData.message = null;
                    break;
                case 'tms':
                    action = formData.teammessage;
                    formData.message = null;
                    break;
                default:
                    action = null;
            }

            onSubmit({
                ...formData,
                days: parseInt(formData.days),
                performdays: parseInt(formData.performdays),
                carriedoutby: formData.carriedoutby,
                employee: formData.employee ? parseInt(formData.employee) : null,
                actiontype: formData.actiontype,
                action: action,
                message: formData.message ? parseInt(formData.message) : null
            });
        }
    };

    const nextStep = () => {
        if (!buttonsEnabled) return;

        if (validateStep()) {
            setButtonsEnabled(false);
            let nextStepIndex = currentStep + 1;

            while (nextStepIndex < steps.length) {
                if (!steps[nextStepIndex].condition || steps[nextStepIndex].condition()) {
                    setCurrentStep(nextStepIndex);
                    break;
                }
                nextStepIndex++;
            }

            if (nextStepIndex >= steps.length) {
                setLastStep(true);
            }
        }
    };

    const prevStep = () => {
        if (!buttonsEnabled) return;
        setButtonsEnabled(false);
        setErrors({});
        let prevStepIndex = currentStep - 1;

        while (prevStepIndex >= 0) {
            if (!steps[prevStepIndex].condition || steps[prevStepIndex].condition()) {
                setCurrentStep(prevStepIndex);
                break;
            }
            prevStepIndex--;
        }
    };

    const isLastStep = () => {
        if (formData.carriedoutby === 'system') {
            // System gebruiker flow
            switch (formData.actiontype) {
                case 'slk':
                    return steps[currentStep].id === 8;  // Na Slack message
                case 'tms':
                    return steps[currentStep].id === 9;  // Na Team message
                case 'eml':
                    return steps[currentStep].id === 10; // Na Message
                case 'nmb':
                case 'afs':
                case 'prs':
                case 'rcr':
                case 'rci':
                case 'rec':
                case 'sec':
                case 'fpd':
                case 'iys':
                case 'sci':
                    return steps[currentStep].id === 6;  // Na Action type voor system-only acties
                default:
                    return false;
            }
        } else {
            // Niet-system gebruiker flow (intern/extern)
            if (formData.actiontype === 'tsk' || formData.actiontype === 'emp') {
                return steps[currentStep].id === 10;     // Na Message (die volgt na Task details)
            } else if (formData.actiontype === 'sec' || formData.actiontype === 'fpd' || formData.actiontype === 'iys' || formData.actiontype === 'sci') {
                return steps[currentStep].id === 10;     // Na Message (direct na Action type)
            }
            return false;
        }
    };

    useEffect(() => {
        setLastStep(isLastStep());
    }, [currentStep, formData.actiontype]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axiosInstance.get('/employees');
                const filteredAndSortedEmployees = response.data
                    .filter(employee => employee.isemployed)
                    .sort((a, b) => {
                        if (a.department < b.department) return -1;
                        if (a.department > b.department) return 1;

                        if (a.lastname < b.lastname) return -1;
                        if (a.lastname > b.lastname) return 1;
                        if (a.firstname < b.firstname) return -1;
                        if (a.firstname > b.firstname) return 1;

                        return 0;
                    });
                setEmployees(filteredAndSortedEmployees);
            } catch (error) {
                console.error('Error fetching employees:', error);
                setErrors(prev => ({ ...prev, Employees: translate('Failed to load employees') }));
            }
        };

        const fetchMessages = async () => {
            try {
                const response = await axiosInstance.get('/messages/titles');
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
                setErrors(prev => ({ ...prev, Employees: translate('Failed to load messages') }));
            }
        };

        fetchEmployees();
        fetchMessages();

        if (steps[currentStep].condition && !steps[currentStep].condition()) {
            nextStep();
        }

        const timer = setTimeout(() => {
            setButtonsEnabled(true);
        }, 500);

        return () => clearTimeout(timer);
    }, [formData.carriedoutby, formData.actiontype, currentStep]);
    const renderField = (field) => {
        switch (field.name) {
            case 'name':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="name">{translate('Name')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder={translate('Action name')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'description':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="description">{translate('Description')}</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    rows="4"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder={translate('Description')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'days':
                let labelText, minValue, maxValue;
                switch (type) {
                    case 1:
                        labelText = translate('Days before first working day');
                        minValue = 0;
                        maxValue = 50;
                        break;
                    case 2:
                        labelText = translate('Days after first working day');
                        minValue = 0;
                        maxValue = 50;
                        break;
                    case 3:
                        labelText = translate('Days before or after reboarding day');
                        minValue = -50;
                        maxValue = 50;
                        break;
                    case 4:
                        labelText = translate('Days before or after offboarding');
                        minValue = -50;
                        maxValue = 50;
                        break;
                    default:
                        labelText = translate('Days');
                        minValue = 0;
                        maxValue = 50;
                }
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="days">{labelText}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="days"
                                    name="days"
                                    min={minValue}
                                    max={maxValue}
                                    step="1"
                                    value={formData.days}
                                    onChange={handleChange}
                                    placeholder={translate('Number of days')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'performdays':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="performdays">{translate('How many days to perform this action')}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="performdays"
                                    name="performdays"
                                    min="0"
                                    max="50"
                                    step="1"
                                    value={formData.performdays}
                                    onChange={handleChange}
                                    placeholder={translate('Number of days')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'carriedoutby':
                let clabelText;
                switch (type) {
                    case 1:
                    case 2:
                        clabelText = translate('The new employee');
                        break;
                    case 3:
                    case 4:
                        clabelText = translate('The employee');
                        break;
                    default:
                        clabelText = translate('Unknown');
                }
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label>{translate('Carried out by')}</label>
                                <div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="carriedoutby"
                                            id="carriedOutByExtern"
                                            value="extern"
                                            checked={formData.carriedoutby === 'extern'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="carriedOutByExtern">
                                            {clabelText}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="carriedoutby"
                                            id="carriedOutByIntern"
                                            value="intern"
                                            checked={formData.carriedoutby === 'intern'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="carriedOutByIntern">
                                            {translate('Employee')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="carriedoutby"
                                            id="carriedOutBySystem"
                                            value="system"
                                            checked={formData.carriedoutby === 'system'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="carriedOutBySystem">
                                            {translate('System')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'employee':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="employee">{translate('Choose employee')}</label>
                                <select
                                    className="form-control"
                                    id="employee"
                                    name="employee"
                                    value={formData.employee}
                                    onChange={handleChange}
                                >
                                    <option value="">{translate('Select an employee')}</option>
                                    {employees.reduce((acc, employee, index, array) => {
                                        const isDepartmentChange = index === 0 || employee.department !== array[index - 1].department;
                                        if (isDepartmentChange) {
                                            acc.push(
                                                <optgroup key={employee.department} label={employee.department}>
                                                    {array.slice(index).filter(e => e.department === employee.department).map(e => (
                                                        <option key={e.id} value={e.id}>
                                                            {e.firstname} {e.lastname}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            );
                                        }
                                        return acc;
                                    }, [])}
                                </select>
                            </div>
                        </div>
                    </div>
                );

            case 'actiontype':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="actiontype">
                                    {formData.carriedoutby === 'system'
                                        ? translate('Select the action to be performed')
                                        : translate('Select the task type')
                                    }
                                </label>
                                <select
                                    className="form-control"
                                    id="actiontype"
                                    name="actiontype"
                                    value={formData.actiontype}
                                    onChange={handleChange}
                                >
                                    <option value="">{translate('Select an action type')}</option>

                                    {formData.carriedoutby !== 'system' && (
                                        <>
                                            <option value="emp">{translate('No task')}</option>
                                            <option value="tsk">{translate('Custom task')}</option>
                                        </>
                                    )};

                                    {formData.carriedoutby === 'system' ? (
                                        // System options
                                        Object.entries(actionTypes)
                                            .filter(([group]) => group === 'messages' || group === 'integrations' || group === 'checks')
                                            .map(([group, items]) => (
                                                <optgroup key={group} label={translate(group.charAt(0).toUpperCase() + group.slice(1))}>
                                                    {items.map(item => (
                                                        <option key={item.value} value={item.value}>
                                                            {translate(item.label)}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))
                                    ) : formData.carriedoutby === 'intern' ? (
                                        // Intern tasks
                                        actionTypes.interntasks.map(item => (
                                            <option key={item.value} value={item.value}>
                                                {translate(item.label)}
                                            </option>
                                        ))
                                    ) : (
                                        // Extern tasks   
                                        actionTypes.externtasks.map(item => (
                                            <option key={item.value} value={item.value}>
                                                {translate(item.label)}
                                            </option>
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                );

            case 'task':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="task">{translate('Task to perform')}</label>
                                <input
                                    className="form-control"
                                    id="task"
                                    type="text"
                                    name="task"
                                    value={formData.task}
                                    onChange={handleChange}
                                    placeholder={translate('Task to perform')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'slackmessage':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="slackmessage">{translate('The message to be sent')}</label>
                                <input
                                    className="form-control"
                                    id="slackmessage"
                                    type="text"
                                    name="slackmessage"
                                    value={formData.slackmessage}
                                    onChange={handleChange}
                                    placeholder={translate('The message to be sent')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'teammessage':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="teammessage">{translate('The message to be sent')}</label>
                                <input
                                    className="form-control"
                                    id="teammessage"
                                    type="text"
                                    name="teammessage"
                                    value={formData.teammessage}
                                    onChange={handleChange}
                                    placeholder={translate('The message to be sent')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'message':
                return (
                    <div className="row" key={`row-${field.name}`}>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <label htmlFor="message">{translate('Select the message to be sent here')}</label>
                                <select
                                    className="form-control"
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                >
                                    <option value="">{translate('Select a message')}</option>
                                    {messages.map(message => (
                                        <option key={message.messageId} value={message.messageId}>
                                            {message.subject}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const renderErrorBar = () => {
        const errorMessages = Object.values(errors).filter(Boolean);
        if (errorMessages.length === 0) return null;

        return (
            <div className="alert alert-danger mb-3" role="alert">
                <FaExclamationTriangle className="me-2" />
                <strong>{translate('Please fill in the following fields')}:</strong>
                <ul className="mb-0 mt-1">
                    {errorMessages.map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <>
            <div className="container">
                <div className="position-relative" style={modalStyle}>
                    <div className="card-body">
                        {renderErrorBar()}
                        <h2 className="card-title mb-4">{steps[currentStep].title}</h2>
                        <form onSubmit={handleSubmit}>
                            {steps[currentStep].fields.map(renderField)}
                            <div className="row mt-4 footer-buttons">
                                <div className="col-4">
                                    <button type="button" onClick={onClose} className="btn btn-outline-secondary btn-sm">
                                        {translate('Cancel')}
                                    </button>
                                </div>
                                <div className="col-8 text-end">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm me-2"
                                        onClick={prevStep}
                                        disabled={currentStep === 0}
                                    >
                                        <FaChevronLeft className="me-1" /> {translate('Previous')}
                                    </button>
                                    {laststep === false ? (
                                        <button type="button" className="btn btn-primary btn-sm" onMouseDown={nextStep}>
                                            {translate('Next')} <FaChevronRight className="ms-1" />
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-success btn-sm">
                                            {editingItem ? translate('Update') : translate('Save')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};


export default BoardingflowPopup;