import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import '../../styling/Perform.css';
 
const ComponentMap = {
    'sec': React.lazy(() => import('./Sendemployeecontract')),
    'tsk': React.lazy(() => import('./Task')),
    'iys': React.lazy(() => import('./Introduceyourself')),
    'sci': React.lazy(() => import('./Uploadcopyid')),
    'fpd': React.lazy(() => import('./Introduceyourself')),
    // Voeg hier meer componenten toe
};

const Perform = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t: translate } = useTranslation();
    const { code } = useParams();
     
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isCodeVerified, setCodeIsVerified] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [action, setAction] = useState(null);
    const [guid, setGuid] = useState(null);
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); 


    // Validate code and check verification status
    useEffect(() => {
        const initializePerform = async () => {
            if (!code || !/^[a-zA-Z0-9-_]+$/.test(code)) {
                navigate('/error');
                return;
            }

            try {
                const response = await axiosInstance.get(`/perform/status/${code}`); 
                if (response.data.verified) {
                    setCodeIsVerified(true);
                    localStorage.setItem('actionToken', response.data.actionToken);
                }
            } catch (err) {
                console.error('Error checking verification status:', err);
                setError(translate('Error checking verification status'));
            } finally {
                ///setLoading(false);
            }
        };

        initializePerform();
    }, [code, navigate, translate]);
     
    const sendAccessCode = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/perform/sendaccesscode', {
                code
            });

            if (response.data.success) {
                localStorage.setItem('actionToken', response.data.actionToken);
                setIsCodeSent(true);
            } else {
                setError(translate('Failed to send access code'));
            }
        } catch (err) {
            console.error('Error:', err);
            setError(translate('Error sending access code'));
        } finally {
            setLoading(false);
        }
    };

    const verifyCode = async (manualCode) => {
        if (loading) return;

        setLoading(true);
        setError('');
        const fullCode = manualCode || verificationCode.join('');

        try {
            const response = await axiosInstance.post('/perform/verifycode', {
                code,
                verificationCode: fullCode
            });

            if (response.data.success) {
                localStorage.setItem('actionToken', response.data.actionToken);
                setIsVerified(true); 
                getAction();
            } else {
                setError(translate('Invalid code'));
                setVerificationCode(['', '', '', '', '', '']);
                const firstInput = document.getElementById('code-0');
                if (firstInput) firstInput.focus();
            }
        } catch (err) {
            console.error('Verification error:', err);
            setError(translate('Error verifying code'));
            setVerificationCode(['', '', '', '', '', '']);
        } finally {
            setLoading(false);
        }
    };

    const getAction = async () => {
        if (loading) return;

        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/perform/action', {
                code
            });

            if (response.data.success) {
                localStorage.setItem('actionToken', response.data.actionToken);
                setAction(response.data.action);
                setGuid(response.data.guid);
            } else {
                setError(translate('Invalid code'));
                setAction(null); 
            }
        } catch (err) {
            console.error('Load action error:', err);
            setError(translate('Error load action'));
            setAction(null); 
        } finally {
            setLoading(false);
        }
    };



    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(pastedText)) {
            const newCode = pastedText.split('');
            setVerificationCode(newCode);
            setTimeout(() => verifyCode(pastedText), 100);
        }
    };
    const getActionComponent = (action, guid) => {
        const Component = ComponentMap[action] || null; // fallback
        return ( 
            <React.Suspense fallback={<div>Loading {action}...</div>}>
                <Component guid={guid} />
            </React.Suspense>
        );
    };

    const handleCodeChange = (index, value) => {
        if (value.length <= 1 && /^\d*$/.test(value)) {
            const newCode = [...verificationCode];
            newCode[index] = value;
            setVerificationCode(newCode);

            if (value && index < 5) {
                const nextInput = document.getElementById(`code-${index + 1}`);
                if (nextInput) nextInput.focus();
            }
            if (index === 5 && value) {
                const fullCode = [...newCode.slice(0, 5), value].join('');
                if (fullCode.length === 6) {
                    verifyCode(fullCode);
                }
            }
        }
    };


    // If verified, show the contract component
    if (isVerified && action != null && guid != null) {
        return (
            <div className="perform-page">
                <div className="perform-container">
                    <div className="perform-header">
                        <span className="perform-logo">Month 1</span>
                        <h1>{translate('Perform action')}</h1>
                    </div>
                    {getActionComponent(action, guid)}
                </div>
            </div>
        );
    }

    return (
        <div className="perform-page">
            <div className="perform-container">
                <div className="perform-header">
                    <span className="perform-logo">Month 1</span>
                    <h1>{translate('Perform action')}</h1>
                </div>

                {error && (
                    <div className="error-message" role="alert">
                        {error}
                    </div>
                )}

                {!isCodeSent ? (
                    <div className="send-code-container">
                        <center>
                            {isCodeVerified && (
                            <button
                                className="send-code-button"
                                onClick={sendAccessCode}
                                disabled={loading}
                            >
                                {loading ? translate('Sending...') : translate('Send access code')}
                                </button>
                            )}
                        </center>
                    </div>
                ) : (
                    <div className="verification-container">
                        <center>{translate('Enter the 6-digit code sent to your email')}
                        <div className="code-inputs">
                            {verificationCode.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`code-${index}`}
                                    type="text"
                                    inputMode="numeric"
                                    pattern="\d*"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleCodeChange(index, e.target.value)}
                                    onPaste={handlePaste}
                                    className="code-input"
                                    autoFocus={index === 0}
                                    disabled={loading}
                                />
                            ))}
                                </div>

                                <button
                                    className="send-code-button"
                                    onClick={sendAccessCode}
                                    style={{ marginRight: '10px' }}
                                    disabled={loading}
                                >
                                    {loading ? translate('Sending...') : translate('Re-send access code')}
                                </button>

                        <button
                            className="verify-button"
                            onClick={() => verifyCode()}
                            disabled={loading || verificationCode.join('').length !== 6}
                        >
                            {loading ? translate('Verifying...') : translate('Verify')}
                            </button>
                        </center>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Perform;