import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { CiSquarePlus } from "react-icons/ci";
import { useTranslation } from 'react-i18next';

const Employee = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const employeeId = location.state?.employeeId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [managers, setManagers] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isAdding2, setIsAdding2] = useState(false);
    const [newNationality, setNewNationality] = useState(''); 
    const [isPreferredNameManuallySet, setIsPreferredNameManuallySet] = useState(false);
    const [newCountry, setNewCountry] = useState('');
    const { i18n } = useTranslation();

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});


    const fetchCountries = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/country/list/' + i18n.language);
            setCountries(response.data);
            setNationalities(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, []);

    const fetchDepartments = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/departments');
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching department:', error);
        }
    }, []);

    const fetchLanguages = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/languages/active');
            setLanguages(response.data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    }, []);

    const fetchLocations = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/locations');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }, []);

    const fetchManagers = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/managers');
            setManagers(response.data);
        } catch (error) {
            console.error('Error fetching managers:', error);
        }
    }, []);

    useEffect(() => {
        setActivePage(translate('Employee'));
        fetchLanguages();
        fetchCountries();
        fetchDepartments();
        fetchLocations();
        fetchManagers();

        const fetchData = async () => {
            try {
                setLoading(true);
                if (employeeId > 0) {
                    const response = await axiosInstance.get('/employee/' + employeeId);
                    setInputValues(response.data);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchData();
    }, [setActivePage, translate, employeeId, fetchDepartments, fetchLocations, fetchManagers]);



    const handleCancel = (e) => {
        e.preventDefault(); 
        navigate('/organisation/employees');
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'preferredname') {
            setIsPreferredNameManuallySet(value !== '');
        }
    };

    useEffect(() => {
        if (!isPreferredNameManuallySet) {
            const { firstname, lastname } = inputValues;
            let autoPreferredName = '';
            if (firstname && lastname) {
                autoPreferredName = `${firstname} ${lastname}`.trim();
            }
            setInputValues(prevState => ({
                ...prevState,
                preferredname: autoPreferredName
            }));
        }
    }, [inputValues.firstname, inputValues.lastname, isPreferredNameManuallySet]);

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Basic details validation
        if (!inputValues.firstname?.trim()) {
            errors.firstname = translate('First name is required');
            isValid = false;
        }

        if (!inputValues.lastname?.trim()) {
            errors.lastname = translate('Last name is required');
            isValid = false;
        }

        if (!inputValues.preferredname?.trim()) {
            errors.preferredname = translate('Preferred name is required');
            isValid = false;
        }

        if (!inputValues.email?.trim()) {
            errors.email = translate('Work email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
            errors.email = translate('Please enter a valid work email address');
            isValid = false;
        }

        if (inputValues.emailpersonal?.trim() && !/\S+@\S+\.\S+/.test(inputValues.emailpersonal)) {
            errors.emailpersonal = translate('Please enter a valid personal email address');
            isValid = false;
        }

        // Employee details validation
        if (!inputValues.employeenr?.trim()) {
            errors.employeenr = translate('Employee number is required');
            isValid = false;
        }

        if (!inputValues.departmentid) {
            errors.departmentid = translate('Department is required');
            isValid = false;
        }

        if (!inputValues.locationid) {
            errors.locationid = translate('Location is required');
            isValid = false;
        }

        if (!inputValues.managerid) {
            errors.managerid = translate('Manager selection is required');
            isValid = false;
        }

        if (!inputValues.employed) {
            errors.employed = translate('Employment date is required');
            isValid = false;
        }  
        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/employee', inputValues);

            const employeeId = response.data;
            setSuccessMessage(translate('Employee successfully saved!'));
            setTimeout(() => setSuccessMessage(''), 2500);
            setLoading(false);
            navigate('/organisation/employee', { state: { employeeId: employeeId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    }; 

    function decodeHTMLEntities(text) {
        const element = document.createElement('div');
        element.innerHTML = text;
        return element.textContent;
    }

    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedEmployee(inputValues);
        setIsModalDeleteOpen(true);
    };


    const toggleAdding = () => {
        setIsAdding(prev => !prev);
        setNewCountry('');
    };

    const handleAddCountry = (e) => {
        e.preventDefault();
        const trimmedCountry = newCountry.trim();
        if (trimmedCountry) {
            const existingCountry = countries.find(country =>
                country.name.toLowerCase() === trimmedCountry.toLowerCase()
            );
            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, country: existingCountry.code }));
            } else {
                const newCountryCode = Date.now().toString();
                const newCountryObj = { code: newCountryCode, name: trimmedCountry };
                setCountries(prevCountries => [...prevCountries, newCountryObj]);
                setInputValues(prevValues => ({ ...prevValues, country: trimmedCountry }));
            }
            setNewCountry('');
            setIsAdding(false);
        }
    };

    const handleDeleteConfirm = async () => {
        if (selectedEmployee) {
            console.log(`Employee ${selectedEmployee.name} is being deleted`);
            await axiosInstance.delete('/employee/' + selectedEmployee.id);
            navigate('/organisation/employees');
        }
        setIsModalDeleteOpen(false);
    };


    const handleAddNationality = useCallback(() => {
        const trimmedNationality = newNationality.trim();
        if (trimmedNationality) {
            const existingCountry = nationalities.find(country =>
                country.resident.toLowerCase() === trimmedNationality.toLowerCase()
            );
            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, nationality: existingCountry.code }));
            } else {
                const newCountryCode = Date.now().toString();
                const newCountry = { code: newCountryCode, resident: trimmedNationality };
                setNationalities(prevCountries => [...prevCountries, newCountry]);
                setInputValues(prevValues => ({ ...prevValues, nationality: newCountryCode }));
            }
            setNewNationality('');
            setIsAdding(false);
        }
    }, [nationalities, newNationality]);

    const toggleAdding2 = useCallback(() => {
        setIsAdding2(prev => !prev);
        setNewNationality('');
    }, []);

    if (loading) return <div>{translate('Loading...')}</div>; 

    return (
        <>
            {successMessage && (
                <div
                    className={`success-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div
                    className={`error-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiAlertCircle /> {error}
                </div>
            )}


            <form onSubmit={handleSubmit}>

            <div className="row">
                <div className="col-2 pt-1">
                    <strong>Basic details</strong>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="firstname">{translate('Firstname')}:</label>
                                <input
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    maxLength="250"
                                    value={inputValues.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="lastname">{translate('Lastname')}:</label>
                                <input
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    maxLength="250"
                                    value={inputValues.lastname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="preferredname">{translate('Preferred name')}:</label>
                                <input
                                    type="text"
                                    id="preferredname"
                                    name="preferredname"
                                    maxLength="250"
                                    value={inputValues.preferredname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="gender">{translate('Gender')}</label>
                                    <select className="form-control" id="gender" name="gender" value={inputValues.gender} onChange={handleChange} required>
                                        <option value="">{translate('- Choose -')}</option>
                                        <option value="Male">{translate('Male')}</option>
                                        <option value="Female">{translate('Female')}</option>
                                        <option value="Other">{translate('Other')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="dayofbirth">{translate('Day of birth')}:</label>
                                    <input
                                        type="date"
                                        id="dayofbirth"
                                        name="dayofbirth"
                                        maxLength="10"
                                        value={inputValues.dayofbirth}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="form-group">
                                    <label htmlFor="placeofbirth">{translate('Place of birth')}:</label>
                                    <input
                                        type="text"
                                        id="placeofbirth"
                                        name="placeofbirth"
                                        maxLength="200"
                                        value={inputValues.placeofbirth}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group" style={{ position: 'relative' }} >
                                    <label htmlFor="nationality">{translate('Nationality')}:</label>
                                    {!isAdding2 ? (
                                        <div>
                                            <select
                                                value={inputValues.nationality}
                                                onChange={handleChange}
                                                className="form-control"
                                                style={{ width: 'calc(100% - 38px)' }}
                                                name="nationality"
                                                id="nationality"
                                                required
                                            >
                                                <option value="">{translate('- Choose -')}</option>
                                                {nationalities.map((country) => (
                                                    <option key={country.code} value={country.code}>
                                                        {country.resident}
                                                    </option>
                                                ))}
                                            </select>
                                            <CiSquarePlus
                                                style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                                onClick={toggleAdding2}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <input
                                                type="text"
                                                value={newNationality}
                                                maxLength="200"
                                                onChange={(e) => setNewNationality(e.target.value)}
                                                placeholder={translate('Add option')}
                                                style={{ width: 'calc(100% - 228px)' }}
                                                autoFocus
                                            />
                                            <button
                                                onClick={handleAddNationality}
                                                className="save-button"
                                                style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                            >
                                                {translate('Add')}
                                            </button>
                                            <button
                                                onClick={toggleAdding2}
                                                className="cancel-button"
                                                style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                            >
                                                {translate('Cancel')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="civilstatus">{translate('Civil status')}</label>
                                    <select className="form-control" id="civilstatus" name="civilstatus" value={inputValues.civilstatus} onChange={handleChange} required>
                                        <option value="">{translate('- Choose -')}</option>
                                        <option value="Single">{translate('Single')}</option>
                                        <option value="Married">{translate('Married')}</option>
                                        <option value="Divorced">{translate('Divorced')}</option>
                                        <option value="Widowed">{translate('Widowed')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>




                    <hr />
                </div>
                </div>


                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Address</strong>
                    </div>
                    <div className="col-6">


                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="street">{translate('Street')}:</label>
                                    <input
                                        type="text"
                                        id="street"
                                        name="street"
                                        maxLength="250"
                                        value={inputValues.street}
                                        onChange={handleChange}
                                        className={`${validationErrors.street ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.street && (
                                        <div className="invalid-feedback">
                                            {validationErrors.street}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="number">{translate('Number')}:</label>
                                    <input
                                        type="text"
                                        id="number"
                                        name="number"
                                        maxLength="10"
                                        value={inputValues.number}
                                        onChange={handleChange}
                                        className={`${validationErrors.number ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.number && (
                                        <div className="invalid-feedback">
                                            {validationErrors.number}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="zipcode">{translate('Postal Code')}:</label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        maxLength="10"
                                        value={inputValues.zipcode}
                                        onChange={handleChange}
                                        className={`${validationErrors.zipcode ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.zipcode && (
                                        <div className="invalid-feedback">
                                            {validationErrors.zipcode}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="city">{translate('City')}:</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        maxLength="250"
                                        value={inputValues.city}
                                        onChange={handleChange}
                                        className={`${validationErrors.city ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.city && (
                                        <div className="invalid-feedback">
                                            {validationErrors.city}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-group" style={{ position: 'relative' }}>
                            <label htmlFor="country">{translate('Country')}:</label>
                            {!isAdding ? (
                                <div>
                                    <select
                                        value={decodeHTMLEntities(inputValues.country)}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.country ? 'is-invalid' : ''}`}
                                        style={{ width: 'calc(100% - 38px)' }}
                                        name="country"
                                        id="country"
                                        required
                                    >
                                        <option value="">{translate('- Choose -')}</option>
                                        {decodeHTMLEntities(inputValues.country) && Array.isArray(countries) &&
                                            !countries.some(country => decodeHTMLEntities(country.name) === decodeHTMLEntities(inputValues.country)) && inputValues.country !== undefined && (
                                                <option value={decodeHTMLEntities(inputValues.country)}>
                                                    {decodeHTMLEntities(inputValues.country)}
                                                </option>
                                            )}
                                        {Array.isArray(countries) && countries.map((country) => (
                                            <option key={country.id} value={decodeHTMLEntities(country.name)}>
                                                {decodeHTMLEntities(country.name)}
                                            </option>
                                        ))}
                                    </select>
                                    <CiSquarePlus
                                        style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                        onClick={toggleAdding}
                                    />
                                    {validationErrors.country && (
                                        <div className="invalid-feedback">
                                            {validationErrors.country}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <input
                                        type="text"
                                        value={newCountry}
                                        maxLength="200"
                                        onChange={(e) => setNewCountry(e.target.value)}
                                        placeholder={translate('Add option')}
                                        style={{ width: 'calc(100% - 238px)' }}
                                        className={`${validationErrors.country ? 'is-invalid' : ''}`}
                                        autoFocus
                                    />
                                    <button
                                        onClick={handleAddCountry}
                                        className="save-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Add')}
                                    </button>
                                    <button
                                        onClick={toggleAdding}
                                        className="cancel-button"
                                        style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                    >
                                        {translate('Cancel')}
                                    </button>
                                </div>
                            )}
                        </div>
                        <hr />
                    </div>
                </div>



                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Settings</strong>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="language">{translate('Default language')}:</label>
                                    <select
                                        value={inputValues.language}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.language ? 'is-invalid' : ''}`}
                                        name="language"
                                        id="language"
                                        required
                                    >
                                        <option value="">{translate('- Choose -')}</option>
                                        {languages.map((language) => (
                                            <option key={language.id} value={language.code}>
                                                {language.name}
                                            </option>
                                        ))}
                                    </select>
                                    {validationErrors.language && (
                                        <div className="invalid-feedback">
                                            {validationErrors.language}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <hr />
                    </div>
                </div>




            <div className="row">
                <div className="col-2 pt-1">
                    <strong>Employee</strong>
                </div>
                <div className="col-6">

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="employeenr">{translate('Employee nr')}:</label>
                                <input
                                    type="text"
                                    id="employeenr"
                                    name="employeenr"
                                    maxLength="50"
                                    value={inputValues.employeenr}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="departmentid">{translate('Department')}</label>
                                <select
                                    value={inputValues.departmentid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="departmentid"
                                    id="departmentid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {departments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="locationid">{translate('Location')}</label>
                                <select
                                    value={inputValues.locationid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="locationid"
                                    id="locationid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {locations.map((location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="managerid">{translate('Manager')}</label>
                                <select
                                    value={inputValues.managerid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="managerid"
                                    id="managerid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    <option value="-1">{translate('No manager')}</option>
                                    {managers.map((manager) => (
                                        <option key={manager.userid} value={manager.userid}>
                                            {manager.fullname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="employed">{translate('Employment date')}:</label>
                                <input
                                    type="date"
                                    id="employed"
                                    name="employed"
                                    maxLength="10"
                                    value={inputValues.employed}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        </div>

                        <hr />
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Contact</strong>
                    </div>
                    <div className="col-6">

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="emailpersonal">{translate('Personal email')}:</label>
                                    <input
                                        type="email"
                                        id="emailpersonal"
                                        name="emailpersonal"
                                        maxLength="250"
                                        value={inputValues.emailpersonal}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="email">{translate('Work email')}:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        maxLength="250"
                                        value={inputValues.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="phone">{translate('Work phone')}:</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        maxLength="14"
                                        value={inputValues.phone}
                                        onChange={handleChange} 
                                    />
                                </div>
                            </div>
                        </div>

                </div>



            </div>

            <hr />


            <div className="row">
                <div className="col-md-3">
                    {parseInt(inputValues.id) > 0 &&
                        <button onClick={handleDelete} className="remove-button">{translate('Delete')}</button>
                    }
                </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
            </div>


            </form>
            










            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove employee')}
                message={translate('Are you sure you want to delete this employee "{{employeeName}}"?', { employeeName: selectedEmployee?.firstname + ' ' + selectedEmployee?.lastname })}
            />
        </>
    );
};

export default Employee;