import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrello, FiUser, FiSettings } from 'react-icons/fi';
import { FaClipboardList } from "react-icons/fa6";
import { VscSettings } from "react-icons/vsc";
import { TiMessages } from "react-icons/ti";
import { RiFlowChart } from "react-icons/ri";
import { LiaLanguageSolid } from "react-icons/lia";
import { MdSchool } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoIosPeople } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";



const useSidebarItems = () => {
    const { t: translate } = useTranslation();

    return [
        {
            name: translate('menu.Tasks'),
            icon: <FiTrello />,
            path: '/tasks'
        },
        {
            name: translate('menu.Boarding'),
            icon: <FiUser />,
            subItems: [
                { icon: <FaClipboardList />, name: translate('menu.Preboarding'), path: '/boarding/preboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Onboarding'), path: '/boarding/onboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Reboarding'), path: '/boarding/reboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Offboarding'), path: '/boarding/offboarding' }
            ],
            isOpen: false,
            path: '/boarding'
        },
        {
            name: 'Training',
            icon: <MdSchool />,
            subItems: [{
                icon: <MdSchool />,
                name: translate('menu.E-Learning'),
                path: '/elearnings'
            }]
        },
        {
            name: 'Organisatie',
            icon: <IoIosPeople />,
            subItems: [
                { icon: <HiOutlineBuildingOffice />, name: translate('menu.Departments'), path: '/organisation/departments' },
                { icon: <CiLocationOn />, name: translate('menu.Locations'), path: '/organisation/locations' },
                { icon: <IoIosPeople />, name: translate('menu.Employees'), path: '/organisation/employees' },
                { icon: <FiUsers />, name: translate('menu.Accounts'), path: '/organisation/accounts' },



            ],
            isOpen: false,
            path: '/configuration'
        },
        {
            name: 'Instellingen',
            icon: <FiSettings />,
            subItems: [
                { icon: <VscSettings />, name: translate('menu.Configuration'), path: '/settings/configuration' },
                { icon: <LiaLanguageSolid />, name: translate('menu.Languages'), path: '/settings/languages' },
                { icon: <TiMessages />, name: translate('menu.Messages'), path: '/settings/messages' },
                { icon: <RiFlowChart />, name: translate('menu.Boardingflows'), path: '/settings/boarding' }
            ],
            isOpen: false,
            path: '/configuration'
        },
    ];
};

export default useSidebarItems;