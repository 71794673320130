import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import axiosInstance from '../../../utils/axiosConfig';
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const Message = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const messageId = location.state?.messageId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const editorRef = useRef(null);

    const [inputValues, setInputValues] = useState({
        subject: '',
        messageid: 0,
        body: '',
        blocked: false
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const customTags = [
        { value: 'ID-Submit', text: 'Submit copy ID' },
        { value: 'Knowledge-test', text: 'Knowledge test' },
        { value: 'Safety-test', text: 'Safety test' },
    ];
    const customTags2 = [
        { value: 'Action', text: 'Action' },
        { value: 'Action Firstname', text: 'Action Firstname' },
        { value: 'Action Lastname', text: 'Action Lastname' },
        { value: 'Action Fullname', text: 'Action Fullname' },
        { value: 'Action Days', text: 'Action Days' }, 

        { value: 'Manager Title', text: 'Manager Title' },
        { value: 'Manager Firstname', text: 'Manager Firstname' },
        { value: 'Manager Lastname', text: 'Manager Lastname' },
        { value: 'Manager Fullname', text: 'Manager Fulllname' },
        { value: 'Manager Email', text: 'Manager Email' },
        { value: 'Manager Phone Number', text: 'Manager Phone Number' },

        { value: 'New Employee Firstname', text: 'New Employee Firstname' },
        { value: 'New Employee Lastname', text: 'New Employee Lastname' },
        { value: 'New Employee Fullname', text: 'New Employee Fullname' },
        { value: 'New Employee Department', text: 'New Employee Department' },
        { value: 'New Employee Location', text: 'New Employee Location' },

        { value: 'Company Name', text: 'Company Name' },
        { value: 'Office Address', text: 'Office Address' }, 
        { value: 'Start Date', text: 'Start Date' },
        //{ value: 'Time', text: 'Time' }, 
    ];

    // Verplaats fetchData buiten useEffect zodat het herbruikbaar is
    const fetchData = async () => {
        try {
            setLoading(true);
            if (messageId > 0) {
                const response = await axiosInstance.get('/message/' + messageId);
                setInputValues(response.data);
            }
            setLoading(false);
            setEditorLoaded(true);
        } catch (err) {
            setError('An error occurred while fetching the data');
            setLoading(false);
        }
    };


    useEffect(() => {
        setActivePage(translate('Message')); 
        fetchData();
    }, [setActivePage, translate, messageId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prev => ({ ...prev, [name]: value }));
    }; 


    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Validate subject
        if (!inputValues.subject?.trim()) {
            errors.subject = translate('Subject is required');
            isValid = false;
        } else if (inputValues.subject.trim().length < 3) {
            errors.subject = translate('Subject must be at least 3 characters long');
            isValid = false;
        } else if (inputValues.subject.trim().length > 200) {
            errors.subject = translate('Subject cannot be longer than 200 characters');
            isValid = false;
        }

        // Validate body
        if (!inputValues.body?.trim()) {
            errors.body = translate('Message body is required');
            isValid = false;
        } else if (inputValues.body.trim().length < 10) {
            errors.body = translate('Message body must be at least 10 characters long');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true); 
            const response = await axiosInstance.post('/message', inputValues);

            const responseId = response.data;
            setSuccessMessage(translate('Message successfully saved!'));
            setLoading(false);
            setTimeout(() => setSuccessMessage(''), 2500);

            setInputValues(prevValues => ({
                ...prevValues, 
                messageid: responseId
            }));

            fetchData();
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };

    const handleCancel = (e) => {
        e.preventDefault(); 
        navigate('/settings/messages');
    };

    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedMessage(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedMessage) {
            console.log(`Message ${selectedMessage.subject} is being deleted`);
            await axiosInstance.delete('/message/' + selectedMessage.messageid);
            navigate('/settings/messages');
        }
        setIsModalDeleteOpen(false);
    };


    const handleEditorChange = (content, editor) => {
        setInputValues(prev => ({ ...prev, body: content }));
    };

    const handleCustomTagChange = (value) => { 
        if (editorRef.current) {
            const editor = editorRef.current;
            editor.insertContent(`<span class="custom-tag">[${value}]</span>`);
            editor.focus();
        }
    };
    const handleCustomTagChange2 = (value) => {
        if (editorRef.current) {
            const editor = editorRef.current;
            if (value.startsWith('Action')) {
                editor.insertContent(`<span class="custom-tag">[${value}]</span>`);
            } else {
                editor.insertContent(`<span class="custom-tag2">[${value}]</span>`);
            }
            editor.focus();
        }
    };

    if (loading) return <div>{translate('Loading...')}</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            {successMessage && (
                <div
                    className={`success-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div
                    className={`error-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiAlertCircle /> {error}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="subject">{translate('Subject')}:</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={inputValues.subject}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        <Editor
                            onInit={(evt, editor) => {
                                editorRef.current = editor;
                                editor.dom.addStyle('.custom-tag { color: #98678f; font-weight: 500; font-style: italic; }');
                                editor.dom.addStyle('.custom-tag2 { color: #33a49f; font-weight: 500; font-style: italic; }');
                            }}
                            initialValue={inputValues.body}
                            apiKey="cob1jh7cnaf8bm7ea04qequ9mw4bmjoi897h0jq5r8ah35xo"
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                statusbar: false,
                                toolbar:
                                    'undo redo | styles | formatselect | bold italic underline | forecolor backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help | customTag2',
                                setup: (editor) => {
                                    editor.on('change', () => {
                                        const content = editor.getContent();
                                        setInputValues(prev => ({ ...prev, body: content }));
                                    });

                                    editor.ui.registry.addMenuButton('customTag', {
                                        text: 'Insert Action',
                                        fetch: (callback) => {
                                            const items = customTags.map((tag) => ({
                                                type: 'menuitem',
                                                text: tag.text,
                                                onAction: () => handleCustomTagChange(tag.value),
                                            }));
                                            callback(items);
                                        },
                                    });

                                    editor.ui.registry.addMenuButton('customTag2', {
                                        text: 'Insert Details',
                                        fetch: (callback) => {
                                            const items = customTags2.map((tag) => ({
                                                type: 'menuitem',
                                                text: tag.text,
                                                onAction: () => handleCustomTagChange2(tag.value),
                                            }));
                                            callback(items);
                                        },
                                    });
                                },
                            }} 
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                <div className="col-6">
                    {inputValues.messageId !== 0 && inputValues.blocked == false &&
                        <button onClick={handleDelete} className="remove-button">{translate('Delete')}</button>
                    }
                </div>
                    <div className="col-6">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                    </div>
                </div>
            </form>
            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove message')}
                message={translate('Are you sure you want to delete this message "{{messageName}}"?', { messageName: selectedMessage?.subject })}
            />
        </>
    );
};

export default Message;