import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NewButton, SearchComponent, ExportComponent } from '../../includes';
import { FaRegEdit, FaRegTrashAlt, FaHome } from "react-icons/fa";
import ConfirmationModal from '../../includes/ConfirmationModal';
import { MdBusinessCenter } from "react-icons/md";
import { useTranslation } from 'react-i18next';


const Employees = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);

    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        navigate('/organisation/employee', { state: { employeeId: row.id } });
    }, [navigate]);
     

    const columns = [
        {
            name: translate('Employee nr'),
            selector: row => row.employeenr,
            sortable: true,
            width: '120px',
            reorder: true,
        }, 
        {
            name: translate('Fullname'), 
            selector: row => <a href="#" onClick={(e) => handleEdit(e, row)}>{row.title}</a>,
            sortable: true,
            reorder: true,
        }, 
        {
            name: translate('Email'),
            selector: row => (
                <>
                    {row.isemployed ? (
                        <>
                            <MdBusinessCenter style={{ marginRight: '8px', fontSize: '18px', opacity: '0.65' }} />
                            <a href={`mailto:${row.email}`}>{row.email}</a>
                        </>
                    ) : (
                        <>
                                <FaHome style={{ marginRight: '8px', fontSize: '18px', opacity: '0.65' }} />
                                <a href={`mailto:${row.emailpersonal}`}>{row.emailpersonal}</a>
                        </>
                    )}
                </>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: translate('Department'),
            selector: row => row.department,
            sortable: true,
            reorder: true,
            width: '200px',
        },
        {
            name: translate('Location'),
            selector: row => row.location,
            sortable: true,
            reorder: true,
            width: '300px',
        },
        {
            name: translate('Manager'),
            selector: row => row.manager,
            sortable: true,
            reorder: true,
            width: '200px',
        },
        {
            name: translate('Employed'),
            selector: row => row.employed,
            sortable: true,
            reorder: true,
            width: '140px',
        }, 
    ];

    useEffect(() => {
        setActivePage(translate('Employees')); 

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/employees');
                setData(response.data);
                setFilteredItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <div className="table-header">
                <NewButton to="/organisation/employee" title={translate('+ New employee')} />
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by fullname')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={Array.isArray(data) ? data : []}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Employees;