import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import { NewButton, SearchComponent, ExportComponent } from '../../includes';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import ConfirmationModal from '../../includes/ConfirmationModal';
import { useTranslation } from 'react-i18next';

const Departments = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);

    //const handleEdit = useCallback((row) => {
    //    navigate('/settings/department', { state: { accountId: row.id } });
    //}, [navigate]);

    //const handleDelete = useCallback((row) => {
    //    setSelectedDepartment(row);
    //    setIsModalOpen(true);
    //}, []);

    //const handleDeleteConfirm = useCallback(() => {
    //    if (selectedDepartment) {
    //        // Hier voer je de daadwerkelijke verwijderactie uit
    //        console.log(`Department ${selectedDepartment.title} is being deleted`);
    //        navigate('/department', { state: { accountId: selectedDepartment.id } });
    //    }
    //    setIsModalOpen(false);
    //}, [selectedDepartment, navigate]);


    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        navigate('/organisation/department', { state: { departmentId: row.id } });
    }, [navigate]);

    const columns = [
        {
            name: translate('Name'), 
            selector: row => <a href="#" onClick={(e) => handleEdit(e, row)}>{row.title}</a>,
            sortable: true,
            reorder: true,
        }, 
        //{
        //    name: translate('Name'),   
        //    selector: row => row.name,
        //    sortable: true,
        //    reorder: true,
        //}, 
    ];

    useEffect(() => {
        setActivePage(translate('Departments')); 

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/departments');
                setData(response.data);
                setFilteredItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <div className="table-header">
                <NewButton to="/organisation/department" title={translate('+ New department')} />
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by name')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Departments;