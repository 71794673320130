export const actionTypes = {
    messages: [
        { value: 'slk', label: 'Slack message' },
        { value: 'tms', label: 'Team message' }
    ],
    interntasks: [
        { value: 'sec', label: 'Send employment contract' }, 
    ],
    externtasks: [
        { value: 'sci', label: 'Send copy ID' },
        { value: 'fpd', label: 'Fill in personal details' },
        { value: 'iys', label: 'Introduce yourself' }
    ],
    integrations: [
        { value: 'nmb', label: 'Send details to Nmbrs' },
        { value: 'afs', label: 'Send details to Afas' },
        { value: 'prs', label: 'Send details to Personio' },
        { value: 'rcr', label: 'Send details to Recruitee' }
    ],
    checks: [
        { value: 'rec', label: 'Received signed employment contract' }
    ]
};