import React, { useState } from 'react';
import '../styling/Tabs.css';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <div className="tabs-container">
            <div className="tabs-header">
                <div className="tabs-list">
                    {tabs.map((tab, index) => (
                        <button
                            key={tab.name}
                            className={`tab ${activeTab === index ? 'active' : ''} ${tab.name === null ? 'disabled' : ''}`}
                            disabled={tab.name === null}
                            onClick={() => setActiveTab(index)}
                        >
                            {tab.name}
                        </button>
                    ))}
                </div>
                <div
                    className="tab-indicator"
                    style={{
                        left: `calc(${activeTab * (100 / tabs.length)}% + 10px)`,
                        width: `calc(${100 / tabs.length}% - 20px)`
                    }}
                ></div>
            </div>
        </div>
    );
};

export default Tabs;