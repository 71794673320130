import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FiPlay, FiChevronDown, FiLoader } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";

const WorkflowPage = () => {
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [workflows, setWorkflows] = useState([]);
    const [workflowSteps, setWorkflowSteps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSteps, setLoadingSteps] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    navigate('/login');
                    return;
                }

                const response = await axiosInstance.get('/flow/preboarding');
                setWorkflows(response.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    navigate('/login');
                } else {
                    setError('Er is een fout opgetreden bij het laden van de workflows.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, [navigate]);

    const handleWorkflowSelect = useCallback(async (workflow) => {
        setSelectedWorkflow(workflow);
        setIsDropdownOpen(false);
        setWorkflowSteps([]);
        setLoadingSteps(true);

        try {
            const stepsResponse = await axiosInstance.get(`/flow/boardingtasks/${workflow.id}`);
            setWorkflowSteps(stepsResponse.data);
        } catch (error) {
            setError('Er is een fout opgetreden bij het laden van de workflow stappen.');
            console.error('Error fetching steps:', error);
        } finally {
            setLoadingSteps(false);
        }
    }, []);
     
    const iframeRef = useRef(null);

    const openUrlInIframe = () => { 
        // Get current datetime in format YYYY-MM-DD HH:mm:ss
        const now = new Date();
        const datetime = now.toISOString().slice(0, 19).replace('T', ' ');

        // Create URL with datetime parameter
        //const baseUrl = 'http://localhost:5814/scheduler.php?code=jouw_geheime_code_hier';
        const baseUrl = 'https://app.month1.nl/api/scheduler.php?code=jouw_geheime_code_hier';
        const url = new URL(baseUrl);
        url.searchParams.append('datetime', datetime);

        // Set the URL to the iframe
        if (iframeRef.current) {
            iframeRef.current.src = url.toString();
        }
    };


    const handleStepActivation = async (step) => {
        try {
            await axiosInstance.put('/action/markasnotdone/' + step.guid);
              
            // Update the local state to mark the step as completed
            setWorkflowSteps(prevSteps =>
                prevSteps.map(s =>
                    s.guid === step.guid
                        ? { ...s, carriedout: '1900-01-01' }
                        : s
                )
            );

            await new Promise(resolve => setTimeout(resolve, 1000));
            openUrlInIframe(); 
        } catch (error) {
            setError('Er is een fout opgetreden bij het uitvoeren van de actie.');
            console.error('Action error:', error);
        }
    };

    if (loading) {
        return <div className="text-center p-5">{'Loading...'}</div>;
    }

    if (error) {
        return (
            <div className="message-container">
                <div className="error-alert">
                    <p>{error}</p>
                    <button
                        onClick={() => navigate('/login')}
                        className="cancel-button"
                    >
                        Terug naar login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-8 perform-page">
            <div className="perform-container">
            <iframe
                ref={iframeRef}
                title="Content Frame"
                border="0"
                width="1"
                height="1"
                className="border-0"
                src="about:blank"
            />
            <div className="max-w-2xl mx-auto bg-white">
                    <div class="perform-header"><span class="perform-logo">Month 1</span><h1>Demo</h1></div>
                <main className="main-content bg-white">

                    <div className="relative mb-8">
                        <button
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="form-control w-full flex items-center justify-between p-3"
                            style={{
                                backgroundColor: '#fff',
                                border: '1px solid #ddd',
                                borderRadius: '4px'
                            }}
                        >
                            <span>{selectedWorkflow ? selectedWorkflow.name : 'Selecteer een workflow'}</span>
                            <FiChevronDown className={`transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
                        </button>

                        {isDropdownOpen && (
                            <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                                {workflows.map((workflow) => (
                                    <button
                                        key={workflow.id}
                                        onClick={() => handleWorkflowSelect(workflow)}
                                        className="w-full p-3 text-left hover:bg-gray-50 bg-white text-black border-bottom"
                                    >
                                        {workflow.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>

                    {selectedWorkflow && (
                        <div className="space-y-4 bg-white">
                            {loadingSteps ? (
                                <div className="flex justify-center p-4">
                                    <FiLoader className="animate-spin text-[#98678f]" size={24} />
                                </div>
                            ) : (
                                workflowSteps.map((step, index) => (
                                    <div key={step.id}
                                        className={`flex items-center justify-between p-2 border rounded-lg ${index === 0 ? 'mt-4' : ''}`}
                                    >
                                        {
                                            step.carriedout === '1900-01-01' ? (
                                                <FaRegCircle style={{ marginRight: '10px' }} />
                                            ) : (
                                                    <FaRegCircleCheck style={{ color: 'green', marginRight: '10px' }} />
                                            )
                                        }
                                        <span>{step.name}</span>
                                        <button
                                            onClick={() => handleStepActivation(step)}
                                            className="action-button"
                                            title="Activeer deze stap"
                                            style={{ float: 'right' }}
                                        >
                                            <FiPlay size={20} />
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </main>
            </div>
        </div>
        </div>
    );
};

export default WorkflowPage;