import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import axiosInstance from './utils/axiosConfig'; 

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/languages/translations/{{lng}}',
            request: (options, url, payload, callback) => {
                axiosInstance.get(url)
                    .then(response => {
                        callback(null, {
                            data: response.data,
                            status: response.status
                        });
                    })
                    .catch(error => {
                        callback(error);
                    });
            }
        }
    });

export default i18n;