import React, { useState, useEffect } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; 
import LoginPage from './components/LoginPage';
import Perform from './components/perform/Index';
import ForgotPasswordPage from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Configuration from './components/configuration/Settings/Configuration';
import Boarding from './components/configuration/Boarding/Boarding'; 

import Preboarding from './components/boarding/Preboarding';
import Preboardingdetails from './components/boarding/Preboardingdetails';
import Preboardingemployee from './components/boarding/Preboardingemployee';
import Preboardingflow from './components/boarding/Preboardingflow';
import Preboardingoverview from './components/boarding/Preboardingoverview';

import Onboarding from './components/boarding/Onboarding';
import Onboardingdetails from './components/boarding/Preboardingdetails'; /* aanpassen */

import Preboardingflowconfig from './components/configuration/Boarding/Preboardingflow';
import Onboardingflowconfig from './components/configuration/Boarding/Onboardingflow';
import Reboardingflowconfig from './components/configuration/Boarding/Reboardingflow';
import Offboardingflowconfig from './components/configuration/Boarding/Offboardingflow';

import Accounts from './components/organisation/Accounts';
import Account from './components/organisation/Account';
import Location from './components/organisation/Location';
import Locations from './components/organisation/Locations';
import Departments from './components/organisation/Departments';
import Department from './components/organisation/Department';
import Employee from './components/organisation/Employee';
import Employees from './components/organisation/Employees';

import Messages from './components/configuration/Settings/Messages';
import Message from './components/configuration/Settings/Message';
import Languages from './components/configuration/Settings/Languages';
import Language from './components/configuration/Settings/Language';


import DemoPage from './components/Demo';

import Tasks from './components/Tasks';
//import Sendemployeecontract from './components/actions/Sendemployeecontract';
//import Fillinpersonaldetails from './components/actions/Fillinpersonaldetails';

import Elearnings from './components/elearning/Elearnings';
import './styling/App.css';

function App() {
    const [activePage, setActivePage] = useState('My to-do');

    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/demo" element={<DemoPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/perform/:code" element={<Perform />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="/reset-password" element={<ResetPassword />} />

                         
                            <Route path="/" element={<ProtectedRoute><Dashboard activePage={activePage} setActivePage={setActivePage} /></ProtectedRoute>}>
                                <Route path="tasks" element={<ProtectedRoute><Tasks setActivePage={setActivePage} /></ProtectedRoute>} />


                                <Route path="boarding">
                                    <Route path="preboarding" element={<ProtectedRoute><Preboarding /></ProtectedRoute>} />
                                    <Route path="preboarding/details" element={<ProtectedRoute><Preboardingdetails /></ProtectedRoute>} />
                                    <Route path="preboarding/employee" element={<ProtectedRoute><Preboardingemployee /></ProtectedRoute>} />
                                    <Route path="preboarding/flow" element={<ProtectedRoute><Preboardingflow /></ProtectedRoute>} />
                                    <Route path="preboarding/overview" element={<ProtectedRoute><Preboardingoverview /></ProtectedRoute>} />


                                    <Route path="onboarding" element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />
                                    <Route path="onboarding/details" element={<ProtectedRoute><Onboardingdetails /></ProtectedRoute>} />



                                    <Route path="reboarding" element={<ProtectedRoute><div>Reboarding Component</div></ProtectedRoute>} />
                                    <Route path="offboarding" element={<ProtectedRoute><div>Offboarding Component</div></ProtectedRoute>} />
                                </Route>

                                <Route path="elearnings" element={<ProtectedRoute><Elearnings /></ProtectedRoute>} />
                                <Route path="elearnings">
                                    <Route path="elearning" element={<ProtectedRoute><div>Elearning</div></ProtectedRoute>} />
                                </Route>
                             
                                <Route path="organisation">
                                    <Route path="accounts" element={<ProtectedRoute><Accounts /></ProtectedRoute>} />
                                    <Route path="account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
                                    <Route path="locations" element={<ProtectedRoute><Locations /></ProtectedRoute>} />
                                    <Route path="location" element={<ProtectedRoute><Location /></ProtectedRoute>} />
                                    <Route path="employees" element={<ProtectedRoute><Employees /></ProtectedRoute>} />
                                    <Route path="employee" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
                                    <Route path="departments" element={<ProtectedRoute><Departments /></ProtectedRoute>} />
                                    <Route path="department" element={<ProtectedRoute><Department /></ProtectedRoute>} />
                                </Route>

                                <Route path="settings">
                                    <Route path="configuration" element={<ProtectedRoute><Configuration setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="languages" element={<ProtectedRoute><Languages setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="language" element={<ProtectedRoute><Language setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
                                    <Route path="message" element={<ProtectedRoute><Message /></ProtectedRoute>} />

                                    <Route path="boarding" element={<ProtectedRoute><Boarding setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="boarding">
                                        <Route path="Onboardingflow" element={<ProtectedRoute><Onboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                        <Route path="Preboardingflow" element={<ProtectedRoute><Preboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                        <Route path="Reboardingflow" element={<ProtectedRoute><Reboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                        <Route path="Offboardingflow" element={<ProtectedRoute><Offboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                    </Route>
                               </Route>

                            </Route> 
                    </Routes>
                </div>
            </Router>
        </I18nextProvider>
    );
}

// ProtectedRoute component to check if user is authenticated
function ProtectedRoute({ children }) {
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
        const validateToken = () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('No token');

                const tokenData = JSON.parse(atob(token.split('.')[1]));
                if (tokenData.exp * 1000 < Date.now()) {
                    localStorage.removeItem('accessToken');
                    throw new Error('Token expired');
                }
            } catch (error) {
                return false;
            }
            return true;
        };

        const isValid = validateToken();
        setIsChecking(false);

        if (!isValid) {
            localStorage.removeItem('accessToken');
        }
    }, []);

    if (isChecking) {
        return <div>Loading...</div>;
    }

    if (!localStorage.getItem('accessToken')) {
        return <Navigate to="/login" replace />;
    }

    return children;
}

export default App;