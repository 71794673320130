import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Account = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const accountId = location.state?.accountid ?? 0;
    const { t: translate } = useTranslation();
    const { setActivePage } = useOutletContext();
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [inputValues, setInputValues] = useState({
        userid: 0,
        firstname: '',
        lastname: '',
        email: '',
        username: '',
        password: '',
        language: 'en',
        active: true
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState('password');
    const [languages, setLanguages] = useState([]);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        setActivePage(translate('Account'));

        fetchEmployees();
        fetchLanguages();
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/account/' + accountId);
                setInputValues(response.data);
                setLoading(false);
            } catch (err) {
                setError(translate('An error occurred while fetching the data'));
                setLoading(false);
            }
        };

        if (accountId > 0) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [accountId, setActivePage, translate]);

    const fetchLanguages = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/languages/active');
            setLanguages(response.data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    }, []);

    const fetchEmployees = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/employees');
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    }, []);

    const handleCancel = (e) => {
        e.preventDefault(); 
        navigate('/organisation/accounts');
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        // Clear validation error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (inputValues.employeeid < 1) { 
            if (!inputValues.firstname.trim()) {
                errors.firstname = translate('Firstname is required');
                isValid = false;
            }

            if (!inputValues.lastname.trim()) {
                errors.lastname = translate('Lastname is required');
                isValid = false;
            }

            if (!inputValues.language) {
                errors.language = translate('Language is required');
                isValid = false;
            }
        } else {
            inputValues.firstname = null;
            inputValues.lastname = null;
            inputValues.language = 0;
        }

        if (!inputValues.email.trim()) {
            errors.email = translate('Email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
            errors.email = translate('Please enter a valid email address');
            isValid = false;
        }
         

        // Only validate password if it's a new account or if password field is not empty
        if (accountId === 0 && !inputValues.password.trim()) {
            errors.password = translate('Password is required for new accounts');
            isValid = false;
        }


        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/account', inputValues);

            const responseId = response.data;
            setSuccessMessage(translate('Account successfully saved!'));
            setLoading(false);
            setTimeout(() => setSuccessMessage(''), 2500);

            setInputValues(prevValues => ({
                ...prevValues,
                password: '',
                userid: responseId
            }));
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };

    const handleDelete = (e) => {
        e.preventDefault(); 
        setSelectedAccount(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedAccount) {
            await axiosInstance.delete('/account/' + selectedAccount.id);
            navigate('/organisation/accounts');
        }
        setIsModalDeleteOpen(false);
    };

    const handleTogglePassword = () => {
        setType(type === 'password' ? 'text' : 'password');
    }

    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : 'xx'}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div className={`error-alert ${isVisible ? 'visible' : ''}`}>
                    <FiAlertCircle /> {error}
                </div>
            )}

            <form onSubmit={handleSubmit}>


                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Connect</strong>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="employeeid">{translate('Employee')}:</label>
                                    <select
                                        value={inputValues.employeeid}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.employeeid ? 'is-invalid' : ''}`}
                                        name="employeeid"
                                        id="employeeid"
                                        required
                                    >
                                        <option value="-1">{translate('- Not connected -')}</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.id}>
                                                {employee.firstname} {employee.lastname}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    <hr className="mt-4" />
                    </div>
                </div>



                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Account status</strong>
                    </div>
                    <div className="col-6">
                        <div className="row mt-1">
                            <div className="col-md-6">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="active"
                                        name="active"
                                        checked={inputValues.active}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="active">{translate('Active')}</label>
                                </div>
                            </div>
                        </div> 
                        <hr className="mt-4" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Login details</strong>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="email">{translate('Email')}:</label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        maxLength="250"
                                        value={inputValues.email}
                                        onChange={handleChange}
                                        readOnly={inputValues.userid !== 0}
                                        className={`form-control ${validationErrors.username ? 'is-invalid' : ''}`}
                                        required
                                    />
                                    {validationErrors.username && (
                                        <div className="invalid-feedback">
                                            {validationErrors.email}
                                        </div>
                                    )}
                                </div>
                            </div> 
                            <div className="col-6">
                                <div className="form-group position-relative">
                                    <label htmlFor="password">{translate('Password')}:</label>
                                    <input
                                        type={type}
                                        id="password"
                                        name="password"
                                        maxLength="64"
                                        value={inputValues.password}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                    />
                                    <span className="passwordvisibility" onClick={handleTogglePassword}>
                                        {type === 'text' ? <FaRegEye /> : <FaRegEyeSlash />}
                                    </span>
                                    <small id="passwordHelp" className="form-text text-muted">
                                        {translate('Only fill in if you want to change the password')}
                                    </small>
                                    {validationErrors.password && (
                                        <div className="invalid-feedback">
                                            {validationErrors.password}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

        {inputValues.employeeid === '-1' && (
            <>
                <div className="row">
                    <div className="col-2 pt-1">
                        <strong>Preferences</strong>
                    </div>
                    <div className="col-6">



                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="firstname">{translate('First name')}:</label>
                                            <input
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                maxLength="100"
                                                value={inputValues.firstname}
                                                onChange={handleChange}
                                                className={`form-control ${validationErrors.firstname ? 'is-invalid' : ''}`}
                                                required
                                            />
                                            {validationErrors.firstname && (
                                                <div className="invalid-feedback">
                                                    {validationErrors.firstname}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname">{translate('Last name')}:</label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                maxLength="100"
                                                value={inputValues.lastname}
                                                onChange={handleChange}
                                                className={`form-control ${validationErrors.lastname ? 'is-invalid' : ''}`}
                                                required
                                            />
                                            {validationErrors.lastname && (
                                                <div className="invalid-feedback">
                                                    {validationErrors.lastname}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <hr />



                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="language">{translate('Default language')}:</label>
                                    <select
                                        value={inputValues.language}
                                        onChange={handleChange}
                                        className={`form-control ${validationErrors.language ? 'is-invalid' : ''}`}
                                        name="language"
                                        id="language"
                                        required
                                    >
                                        <option value="">{translate('- Choose -')}</option>
                                        {languages.map((language) => (
                                            <option key={language.id} value={language.code}>
                                                {language.name}
                                            </option>
                                        ))}
                                    </select>
                                    {validationErrors.language && (
                                        <div className="invalid-feedback">
                                            {validationErrors.language}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )}


                <hr />

                <div className="row">
                    <div className="col-md-3">
                        {parseInt(inputValues.id) > 0 &&
                            <button type="button" onClick={handleDelete} className="remove-button">
                                {translate('Delete')}
                            </button>
                        }
                    </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">{translate('Save')}</button>
                        <button type="button" onClick={handleCancel} className="cancel-button">
                            {translate('Cancel')}
                        </button>
                    </div>
                </div>
            </form>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove account')}
                message={translate('Are you sure you want to delete this account "{{accountName}}"?', { accountName: selectedAccount?.firstname })}
            />
        </>
    );
};

export default Account;