import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import ConfirmationModal from '../../includes/ConfirmationModal';
import AlertModal from '../../includes/AlertModal';
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";
import { FaArrowRight, FaArrowDown, FaCheck, FaEdit, FaHome } from "react-icons/fa";
import { FiInfo, FiUpload } from "react-icons/fi";
import { GrStatusInfo } from "react-icons/gr";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { actionTypes } from '../../includes/actionTypes';
import { MdBusinessCenter } from "react-icons/md";
import defaultavantar from "../../images/default-avatar.png";
import Modal from '../../includes/PopupModal';
import Personaldetails from './Personaldetails';

const Preboardingoverview = () => {
    // Router and context hooks
    const location = useLocation();
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate, i18n } = useTranslation();
    const flowid = location.state?.flowid ?? 0;

    // UI state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalShowIntroduction, setIsModalShowIntroduction] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [expandedTaskId, setExpandedTaskId] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    // Data state
    const [tasks, setTasks] = useState([]);
    const [messages, setMessages] = useState([]);
    const [taskDetails, setTaskDetails] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [avatar, setAvatar] = useState(defaultavantar);
    const [countries, setCountries] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [managers, setManagers] = useState([]);
    const [introductionText, setIntroductionText] = useState('');
    const [inputValues, setInputValues] = useState({
        currentstep: 1,
        typeid: 1,
        template: 0,
        id: 0,
        startdate: '',
        name: '',
        firstname: '',
        lastname: '',
        preferredname: '',
        gender: '',
        dayofbirth: '',
        placeofbirth: '',
        civilstatus: '',
        personalemail: '',
        personalphone: '',
        nationality: ''
    });

    // Action types mapping
    const typeNames = [{ value: 'tsk', label: 'Custom task' }];
    Object.entries(actionTypes).forEach(([category, items]) => {
        items.forEach(item => {
            typeNames.push({ value: item.value, label: item.label });
        });
    });

    // API calls with useCallback
    const fetchData = useCallback(async (url, setter, errorMessage) => {
        try {
            const response = await axiosInstance.get(url);
            setter(response.data);
            return response.data;
        } catch (error) {
            console.error(errorMessage, error);
            setError(error.message);
            return null;
        }
    }, []);

    const fetchCountries = useCallback(() =>
        fetchData('/country/list/' + i18n.language, setCountries, 'Error fetching countries:'),
        [fetchData, i18n.language]);

    const fetchDepartments = useCallback(() =>
        fetchData('/departments', setDepartments, 'Error fetching departments:'),
        [fetchData]);

    const fetchLocations = useCallback(() =>
        fetchData('/locations', setLocations, 'Error fetching locations:'),
        [fetchData]);

    const fetchManagers = useCallback(() =>
        fetchData('/managers', setManagers, 'Error fetching managers:'),
        [fetchData]);

    const fetchMessages = useCallback(() =>
        fetchData('/messages/titles', setMessages, 'Error fetching messages:'),
        [fetchData]);

    const fetchTasks = useCallback(async () => {
        if (!flowid) return;
        return fetchData('/flow/boardingtasks/' + flowid, setTasks, 'Error fetching tasks:');
    }, [fetchData, flowid]);

    const fetchItem = useCallback(async () => {
        if (!flowid) return;
        try {
            const response = await axiosInstance.get('/flow/getboarding/' + flowid);
            setInputValues(response.data);
            setActivePage(translate('Preboarding workflow - ' + response.data.name));
            return response.data;
        } catch (error) {
            console.error('Error fetching item:', error);
            setError(error.message);
            return null;
        }
    }, [flowid, setActivePage, translate]);

    const fetchAvatar = useCallback(async () => {
        if (!flowid) return;
        try {
            const response = await axiosInstance.get(`/flow/avatar/${flowid}?t=${Date.now()}`, {
                responseType: 'blob'
            });
            const imageUrl = URL.createObjectURL(response.data);
            setAvatar(imageUrl);
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
    }, [flowid]);

    // Main data loading effect
    useEffect(() => {
        const loadInitialData = async () => {
            if (flowid === 0) {
                setActivePage(translate('New preboarding'));
                setLoading(false);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const results = await Promise.allSettled([
                    fetchItem(),
                    fetchMessages(),
                    fetchTasks(),
                    fetchCountries(),
                    fetchDepartments(),
                    fetchLocations(),
                    fetchManagers(),
                    fetchAvatar()
                ]);

                // Check if any critical operations failed
                const criticalErrors = results.slice(0, 7).filter(result =>
                    result.status === 'rejected' || (result.status === 'fulfilled' && !result.value)
                );

                if (criticalErrors.length > 0) {
                    throw new Error('Failed to load critical data');
                }
            } catch (error) {
                console.error('Error loading initial data:', error);
                setError('Failed to load data. Please refresh the page or try again later.');
            } finally {
                setLoading(false);
            }
        };

        loadInitialData();
    }, [
        flowid,
        fetchItem,
        fetchMessages,
        fetchTasks,
        fetchCountries,
        fetchDepartments,
        fetchLocations,
        fetchManagers,
        setActivePage,
        translate
    ]);

    // Event Handlers
    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('flowid', flowid);

            const response = await axiosInstance.post('/flow/avatar', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success) {
                await fetchAvatar();
            }
        } catch (error) {
            console.error('Error uploading avatar:', error);
            setError('Failed to upload avatar');
        } finally {
            setIsUploading(false);
        }
    };

    const handleEditEmployeeDetails = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        // Refresh data after modal closes
        fetchItem();
    }, [fetchItem]);

    const handleCancel = useCallback(() => {
        navigate('/boarding/preboarding');
    }, [navigate]);

    const handleMarkAsDone = async (id) => {
        if (loading) return;

        try {
            setLoading(true);
            await axiosInstance.put('/action/markasdone/' + id);
            await fetchTasks();
        } catch (error) {
            console.error('Error marking task as done:', error);
            setError('Failed to mark task as done');
        } finally {
            setLoading(false);
        }
    };

    const handleShowIntroduction = useCallback((data) => {
        try {
            setIntroductionText(JSON.parse(data).introduction);
            setIsModalShowIntroduction(true);
        } catch (e) {
            setIntroductionText('Error: could not load text.');
            setIsModalShowIntroduction(true);
        }
    }, []);

    const handleDownloadFile = async (url, filename) => {
        if (loading) return;

        try {
            setLoading(true);
            const response = await axiosInstance.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
            setError('Failed to download file');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadCopyId = async (data, id) => {
        try {
            const extension = JSON.parse(data).extension;
            await handleDownloadFile(
                `/action/downloadcopyid/${id}`,
                `ID-${id}.${extension}`
            );
        } catch (error) {
            console.error('Error downloading ID:', error);
            setError('Failed to download ID');
        }
    };

    const handleDownloadContract = async (id) => {
        try {
            await handleDownloadFile(
                `/action/downloadcontract/${id}`,
                `contract-${id}.pdf`
            );
        } catch (error) {
            console.error('Error downloading contract:', error);
            setError('Failed to download contract');
        }
    };

    const handleShowDetails = async (id) => {
        if (loading) return;

        try {
            //setLoading(true);
            // Als dezelfde taak wordt aangeklikt, sluit deze dan
            if (expandedTaskId === id) {
                setExpandedTaskId(null);
                setTaskDetails(null);
                //setLoading(false);
                return;
            }

            const response = await axiosInstance.get('/action/getbyguid/' + id);
            setTaskDetails(response.data);
            setExpandedTaskId(id);
        } catch (error) {
            console.error('Error fetching task details:', error);
            setError('Failed to load task details');
        } finally {
            //setLoading(false);
        }
    };

    const handleDelete = useCallback((e) => {
        e.preventDefault();
        setSelectedFlow(inputValues);
        setIsModalDeleteOpen(true);
    }, [inputValues]);

    const handleDeleteConfirm = async () => {
        if (!selectedFlow) return;

        try {
            setLoading(true);
            await axiosInstance.delete('/flow/remove/' + selectedFlow.id);
            navigate('/boarding/preboarding');
        } catch (error) {
            console.error('Error deleting flow:', error);
            setError('Failed to delete flow');
        } finally {
            setLoading(false);
            setIsModalDeleteOpen(false);
        }
    };

    const handleShowIntroductionClose = useCallback(() => {
        setIsModalShowIntroduction(false);
    }, []);

    const isDateAfterToday = useCallback((dateString) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const compareDate = new Date(dateString);
        compareDate.setHours(0, 0, 0, 0);
        return compareDate > today;
    }, []);

    if (loading && !error) {
        return <div className="text-center p-5">{translate('Loading...')}</div>;
    }

    if (error) {
        return (
            <div className="alert alert-danger m-5" role="alert">
                {error}
            </div>
        );
    }

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : ''}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            <div className="row">
                {/* Profile Section */}
                <div className="col-3 profile">
                    <div className="avatar profile-avantar position-relative">
                        <img
                            className="rounded-circle img-thumbnail shadow-sm border-0"
                            src={avatar}
                            alt="Profile avatar"
                        />
                        <label className="avatar-upload-label position-absolute bottom-0 end-0 bg-white rounded-circle p-2 shadow-sm cursor-pointer">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleAvatarChange}
                                className="d-none"
                            />
                            <FiUpload className={`text-primary ${isUploading ? 'opacity-50' : ''}`} />
                        </label>
                    </div>

                    <div className="row mt-4">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col-12">
                                    <strong>{translate('Fullname')}</strong>
                                </div>
                                <div className="col-12">
                                    {inputValues.preferredname}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <strong>{translate('Email address')}</strong>
                                </div>
                                <div className="col-12">
                                    {isDateAfterToday(inputValues.startdate) ? (
                                        <>
                                            <FaHome style={{ marginRight: '8px', fontSize: '18px', opacity: '0.65' }} />
                                            {inputValues.personalemail}
                                        </>
                                    ) : (
                                        <>
                                            <MdBusinessCenter style={{ marginRight: '8px', fontSize: '18px', opacity: '0.65' }} />
                                            {inputValues.workemail}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <strong>{translate('Department')}</strong>
                                </div>
                                <div className="col-12">
                                    {departments.find(t => t.id === inputValues.department)?.name}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <strong>{translate('Location')}</strong>
                                </div>
                                <div className="col-12">
                                    {locations.find(t => t.id === inputValues.location)?.name}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <strong>{translate('Manager')}</strong>
                                </div>
                                <div className="col-12">
                                    {managers.find(t => t.userid === inputValues.manager)?.fullname}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <button onClick={handleEditEmployeeDetails} className="action-button">
                                        <FaEdit style={{ marginRight: '10px' }} />
                                        {translate('Edit employee details')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>

                {/* Tasks Section with Inline Details */}
                <div className="col-9">
                    {tasks && tasks.length > 0 ? (
                        tasks.map((task) => (
                            <React.Fragment key={`row-${task.id}`}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-5 ps-5">
                                                <div className="task-item" style={{ borderColor: '#666' }}>
                                                    <div className="icon-space">
                                                        {task.carriedout === '1900-01-01' ? (
                                                            <FaRegCircle style={{ color: '#666' }} />
                                                        ) : (
                                                            <FaRegCircleCheck style={{ color: '#7bd17b' }} />
                                                        )}
                                                    </div>
                                                    <div className="task-content w-100">
                                                        <div className="task-header">
                                                            <span className="task-space-task">{task.name}</span>
                                                        </div>
                                                        <span className="task-space-name mb-4">
                                                            {task.actiontype === 'rec' && task.carriedout !== '1900-01-01' && (
                                                                <button
                                                                    onClick={() => handleDownloadContract(task.guid)}
                                                                    className="action-button"
                                                                    style={{ float: 'right' }}
                                                                >
                                                                    <AiOutlineCloudDownload style={{ marginRight: '5px' }} />
                                                                    {translate('Download PDF')}
                                                                </button>
                                                            )}
                                                            {task.actiontype === 'sci' && task.carriedout !== '1900-01-01' && (
                                                                <button
                                                                    onClick={() => handleDownloadCopyId(task.data, task.guid)}
                                                                    className="action-button"
                                                                    style={{ float: 'right' }}
                                                                >
                                                                    <AiOutlineCloudDownload style={{ marginRight: '5px' }} />
                                                                    {translate('Download Copy ID')}
                                                                </button>
                                                            )}
                                                            {task.carriedoutbyname}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <span className="task-space-date">
                                                    {task.carriedout === '1900-01-01' ? task.startdate : task.carriedout}
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    onClick={() => handleShowDetails(task.guid)}
                                                    className="action-button"
                                                >
                                                    <GrStatusInfo style={{ marginRight: '10px' }} />
                                                    {expandedTaskId === task.guid ? translate('Hide details') : translate('Show details')}
                                                </button>
                                            </div>
                                            <div className="col-2">
                                                {task.carriedout === '1900-01-01' &&
                                                    !['nmb', 'afs', 'prs', 'rcr', 'rci'].includes(task.actiontype) && (
                                                        <button
                                                            onClick={() => handleMarkAsDone(task.guid)}
                                                            className="action-button"
                                                        >
                                                            <FaCheck style={{ marginRight: '10px' }} />
                                                            {translate('Mark as done')}
                                                        </button>
                                                    )}
                                            </div>
                                            <div className="col-1"></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Inline Task Details */}
                                {expandedTaskId === task.guid && taskDetails && (
                                    <div className="row">
                                        <div className="col-12 taskdetails">
                                            <div className="task-details p-4 bg-light">
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Task')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {taskDetails.name}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Description')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {taskDetails.description}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Carried out by')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {taskDetails.carriedoutbyname}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Carried out date')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {taskDetails.carriedout === '1900-01-01' ? '' : taskDetails.carriedout}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Startdate')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {taskDetails.startdate}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Action type')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {typeNames.find(at => at.value === taskDetails.actiontype)?.label || ''}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-3">
                                                        <strong>{translate('Message')}:</strong>
                                                    </div>
                                                    <div className="col-9">
                                                        {messages.find(msg => msg.messageId === taskDetails.message)?.subject || ''}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <strong>{translate('Data')}:</strong>
                                                    </div>
                                                    <div className="col-9" style={{ "wordWrap": "break-word", "color": "#999" }}>
                                                        {taskDetails.data}
                                                    </div>
                                                </div> 
                                                {taskDetails.actiontype === 'iys' && taskDetails.carriedout !== '1900-01-01' && (
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <button
                                                                onClick={() => handleShowIntroduction(taskDetails.data)}
                                                                className="action-button"
                                                            >
                                                                {translate('Show text')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="row">
                            <div className="col-12 text-center">
                                <p>{loading ? translate('Loading') : translate('No Task Found')}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Modals */}
            <Modal isOpen={isModalOpen} onClose={closeModal} height="500px" width="1000px">
                <Personaldetails onSaveSuccess={closeModal} flowId={flowid} />
            </Modal>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove flow')}
                message={translate('Are you sure you want to delete this flow "{{flowName}}"?', {
                    flowName: selectedFlow?.name
                })}
            />

            <AlertModal
                isOpen={isModalShowIntroduction}
                onClose={() => setIsModalShowIntroduction(false)}
                onConfirm={handleShowIntroductionClose}
                title={translate('Introduction text')}
                message={introductionText}
            />

            {/* Footer Buttons */}
            <div className="row mt-5">
                <div className="col-md-7">
                    <button
                        onClick={handleCancel}
                        className="cancel-button"
                        style={{ float: 'none', marginRight: '15px' }}
                    >
                        {translate('Back')}
                    </button>
                    <button
                        onClick={handleDelete}
                        className="previous-button"
                        style={{ float: 'none' }}
                    >
                        {translate('Delete')}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Preboardingoverview;