import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../utils/axiosConfig';
import { NewButton, SearchComponent } from '../../includes';
import { useTranslation } from 'react-i18next';
import { MdPerson4 } from "react-icons/md";


const Onboardingflows = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);

    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        if (row.currentstep === 1) {
            navigate('/boarding/onboarding/details', { state: { flowid: row.id } });
        } else if (row.currentstep === 2) {
            navigate('/boarding/onboarding/employee', { state: { flowid: row.id } });
        } else if (row.currentstep === 3) {
            navigate('/boarding/onboarding/flow', { state: { flowid: row.id } });
        } else if (row.currentstep === 4) {
            navigate('/boarding/onboarding/overview', { state: { flowid: row.id } });
        }
    }, [navigate]);

    const columns = [
        {
            name: translate('Name'),
            selector: row => <><a href="#" onClick={(e) => handleEdit(e, row)}>{row.name}</a></>,
            sortable: true,
            reorder: true,
        },
        {
            name: translate('Status'),
            selector: row => {
                switch (row.currentstep) {
                    case 1:
                        return translate('Invoeren persoongegevens');
                    case 2:
                        return translate('Invoeren personeel gegevens');
                    case 3:
                        return translate('Controleren workflow');
                    case 4:
                        return translate('Gestart');
                    default:
                        return row.currentstep;
                }
            },
            sortable: true,
            reorder: true,
            width: '250px',
        },
        {
            name: translate('Employee'),
            selector: row => <><MdPerson4 /> {row.fullname}</>,
            sortable: true,
            reorder: true,
            width: '250px',
        },
        {
            name: translate('Tasks'),
            cell: row => <div className="pill">{row.tasks} {translate('task(s)')}</div>,
            sortable: true,
            reorder: true,
            width: '170px',
        },
        {
            name: translate('Start date'),
            selector: row => row.startdate,
            sortable: true,
            reorder: true,
            width: '200px',
        },
    ];

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/flow/onboarding');
            setData(response.data);
            setFilteredItems(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error while try to load the data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setActivePage(translate('Onboarding workflows'));

        fetchData();
    }, [setActivePage, translate]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <div className="table-header">
                <NewButton to="/boarding/onboarding/details" title={translate('+ New workflow')} />
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by flowname')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Onboardingflows;